import Vue from 'vue'
import App from './App.vue'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
Vue.use(ElementUI);
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    preLoad: 1.3, //预加载高度
    // error: require('./assets/images/loading.gif'), //错误展示图片
    // loading: require('./assets/images/loading.gif'), //加载等待图片
    attempt: 1 //尝试次数
});
// 引入 animate动画
import animated from 'animate.css'
Vue.use(animated);

// import './js/test_js.js' 2019/05/16 Sillong


//自适应JS
// import '../config/adapt'



//引用部分element ui组件
// import {Radio} from 'element-ui';
// Vue.use(Radio);


Vue.config.productionTip = false;
new Vue({
  router,
  render: h => h(App),
  mounted () {
      document.dispatchEvent(new Event('render-event'))
  }

}).$mount('#app');
