<template>
    <div class="c-purchase-index-wrap" name="top">
        <!-- 头部 -->
        <Header></Header>
        <!-- 文字描述 -->
        <div class="c-pi-wrap">
            <p>免费试用，选择适合您的版本</p>
            <p>益帮手功能强大，并且易于上手，适用于任何规模的企业或门店。从现在开始，益帮手基础版免费试用15天，
                同时益帮手提供免费版供小型水站免费使用，让梦想加速起航。
            </p>
        </div>
        <div class="c-pi-item-wrap">
            <!-- 基础版 -->
            <div class="c-pii-item">
                <div class="c-piii-header"></div>
                <div class="c-pii-item-detail">
                    <p>免费版</p>
                    <p>适用只需要部分功能用户</p>
                    <!--<div class="c-year-wrap">-->
                        <!--<span>￥</span>-->
                        <!--<span>0</span>-->
                        <!--<span>/年</span>-->
                    <!--</div>-->
                    <!--<div class="c-price-detail"></div>-->
                    <div class="c-piii-qd c-free-used" @click="toFunctionDownload()">咨询客服</div>
                    <div class="c-sites">
                        <div>一间站点</div>

                    </div>
                    <div class="c-pu-line"></div>
                    <div class="c-intruduce-wrap">
                        <p>包含功能</p>
                        <p>订单管理系统</p>
                        <p>客户管理 / 300个客户</p>
                        <p>商品管理 / 10件商品</p>
                        <p>来电弹屏（电话接单）</p>
                        <p>站点管理系统</p>
                        <p>员工管理系统</p>
                    </div>
                    <div class="c-pii-phone">
                        <p>服务</p>
                        <p>电话盒子(额外购买）</p>
                        <p>5×6小时在线支持</p>
                    </div>
                </div>
            </div>
            <!-- 个人版 -->
            <div class="c-pii-item">
                <div class="c-piii-header c-person"></div>
                <div class="c-pii-item-detail">
                    <p>个人版</p>
                    <p>适用小型个体水站</p>
                    <!--<div class="c-year-wrap">-->
                        <!--<span>￥</span>-->
                        <!--<span>998</span>-->
                        <!--<span>/永久</span>-->
                    <!--</div>-->
                    <!--<div class="c-price-detail"></div>-->
                    <div class="c-piii-qd c-free-used" @click="toFunctionDownload()">咨询客服</div>
                    <div class="c-sites">
                        <div>一间站点</div>
                        <div>1个APP账号</div>
                    </div>
                    <div class="c-pu-line"></div>
                    <div class="c-intruduce-wrap">
                        <p>包含功能</p>
                        <p>不限制客户及商品数量</p>
                        <p>订单管理系统</p>
                        <p>手机配送管理系统</p>
                        <p>客户管理系统</p>
                        <p>商品管理系统</p>
                        <p>库存管理系统</p>
                        <p>财务统计系统</p>
                        <p>数据导出</p>
                    </div>
                    <div class="c-pii-phone">
                        <p>服务</p>
                        <p>电话盒子(额外购买）</p>
                        <p>5×6小时在线支持</p>
                    </div>
                </div>

            </div>
            <!-- 基础版 -->
            <div class="c-pii-item">
                <div class="c-piii-header c-depend"></div>
                <div class="c-pii-item-detail">
                    <p>基础版</p>
                    <p>适用中小型水站</p>
                    <!--<div class="c-year-wrap">-->
                        <!--<span>￥</span>-->
                        <!--<span>2400</span>-->
                        <!--<span>/年</span>-->
                    <!--</div>-->
                    <!--<div class="c-price-detail">续费：980/年</div>-->
                    <div class="c-piii-qd c-free-used" @click="toFunctionDownload()">咨询客服</div>
                    <div class="c-sites">
                        <div>1 间站点</div>
                        <div>3个APP账号</div>
                    </div>
                    <div class="c-pu-line"></div>
                    <div class="c-intruduce-wrap">
                        <p>包含功能</p>
                        <p>个人版全部功能</p>
                        <p>微信小程序商城</p>
                        <p>微信公众号管理</p>
                        <p>营销工具</p>

                    </div>
                    <div class="c-pii-phone">
                        <p>服务</p>
                        <p>电话盒子一台(赠送）</p>
                        <p>7×12小时在线支持</p>
                    </div>
                </div>

            </div>
            <!-- 连锁版 -->
            <div class="c-pii-item">
                <div class="c-piii-header c-lx"></div>
                <div class="c-pii-item-detail">
                    <p>连锁直营版</p>
                    <p>适用连锁水站</p>
                    <!--<div class="c-year-wrap">-->
                        <!--<span>￥</span>-->
                        <!--<span>5899</span>-->
                        <!--<span>/年</span>-->
                    <!--</div>-->
                    <!--<div class="c-price-detail">续费：1600/年</div>-->

                    <div class="c-piii-qd c-free-used" @click="toFunctionDownload()">咨询客服</div>
                    <div class="c-sites">
                        <div>不限制站点</div>
                        <div>10个APP账号</div>
                    </div>
                    <!--<div class="c-sites c-sites2">-->
                        <!--<div class="c-three-sites">-->
                            <!--<span><img src="../../assets/images/js.png" alt=""></span>-->
                            <!--<span>无限制</span>-->
                            <!--<span><img src="../../assets/images/zj.png" alt=""></span>-->
                        <!--</div>-->
                        <!--<div class="c-three-sites">-->
                            <!--<span><img src="../../assets/images/js.png" alt=""></span><span>6个APP账号</span><span><img src="../../assets/images/zj.png" alt=""></span></div>-->
                    <!--</div>-->
                    <div class="c-pu-line"></div>
                    <div class="c-intruduce-wrap">
                        <p>包含功能</p>
                        <p>包含基础版所有功能</p>
                        <p>账号价格 6 折</p>
                        <p>多套小程序模板选择</p>
                        <p>付费功能优先体验</p>

                    </div>
                    <div class="c-pii-phone">
                        <p>服务</p>
                        <p>电话盒子一台(赠送）</p>
                        <p>7×12小时在线支持</p>
                    </div>
                </div>



            </div>
            <!-- 私有部署版 -->
            <div class="c-pii-item">
                <div class="c-piii-header c-depend"></div>
                <div class="c-pii-item-detail">
                    <p>私有部署版</p>
                    <p>适用本地独立化部署</p>
                    <div class="c-year-wrap">
                        <img v-lazy="require('../../../assets/images/tc00.png')" alt="">
                    </div>
                    <div class="c-price-detail c-pd02">
                    </div>
                    <div class="c-zxgw" @click="toFunctionDownload()">咨询顾问</div>
                    <div class="c-sites">
                        <div>无限制站点数量</div>
                        <div>无限制APP账号数量</div>
                    </div>
                    <div class="c-pu-line"></div>
                    <div class="c-intruduce-wrap">
                        <p>包含功能</p>
                        <p>包含连锁版所有功能</p>
                        <p>本地私有部署</p>
                        <p>企业域名定制</p>
                        <p>应用LOGO自定义</p>
                        <p>定制功能开发（费用另计）</p>
                        <p>小程序模板定制（费用另计）</p>
                    </div>
                    <div class="c-pii-phone">
                        <p>服务</p>
                        <p>电话盒子一台(赠送）</p>
                        <p>7×12小时在线支持</p>
                    </div>
                </div>



            </div>
        </div>

        <!-- 表格展示详情 -->
        <div class="c-pi-table-wrap">
            <table>
                <tr>
                    <td style="font-size: 1.25rem;color: #707070;">方案对比</td>
                    <td>免费版</td>
                    <td>个人版</td>
                    <td>基础版</td>
                    <td>连锁版</td>
                    <td>私有部署版</td>
                </tr>
                <!-- <img src="../../assets/images/dagou.png" align="center"> -->
                <tr>
                    <td>相关配置</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>超级管理员账号数量</td>
                    <td>1名超管</td>
                    <td>1名超管</td>
                    <td>1名超管</td>
                    <td>1名超管</td>
                    <td>私有部署定制</td>
                </tr>
                <tr>
                    <td>APP账号数量（含超管账号）</td>
                    <td>1名</td>
                    <td>1名</td>
                    <td>2名</td>
                    <td>9名</td>
                    <td>不限</td>
                </tr>
                <tr>
                    <td>站点数量</td>
                    <td>1间站点</td>
                    <td>1间站点</td>
                    <td>1间站点</td>
                    <td>不限制站点</td>
                    <td>不限</td>
                </tr>
                <tr>
                    <td>客户存储量</td>
                    <td>300名客户</td>
                    <td>不限</td>
                    <td>不限</td>
                    <td>不限</td>
                    <td>不限</td>
                </tr>
                <tr>
                    <td>商品数量</td>
                    <td>10件</td>
                    <td>不限</td>
                    <td>不限</td>
                    <td>不限</td>
                    <td>不限</td>
                </tr>
                <tr>
                    <td>电脑端账号数量</td>
                    <td>1名</td>
                    <td>不限</td>
                    <td>不限</td>
                    <td>不限</td>
                    <td>不限</td>
                </tr>
                <tr>
                    <td style="font-size: 1.13rem;color: #aaaaaa;">基础功能</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>订单管理系统</td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>商品管理系统</td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>客户管理系统</td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>手机配送管理系统</td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>员工管理系统</td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>来电弹屏（电话接单）</td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>站点管理系统</td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>财务统计系统</td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>数据导出</td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>微信小程序商城</td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>微信公众号管理</td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td>营销工具</td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/jx15.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                    <td><img v-lazy="require('../../../assets/images/dagou.png')" align="center"></td>
                </tr>
                <tr>
                    <td style="font-size: 1.13rem;color: #aaaaaa;">服务</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>电话盒子</td>
                    <td>180元一台</td>
                    <td>180元一台</td>
                    <td>赠送一台</td>
                    <td>赠送一台</td>
                    <td>赠送一台</td>
                </tr>
                <tr>
                    <td>安全加密</td>
                    <td>本地安全加密</td>
                    <td>阿里云/腾讯云/本地</td>
                    <td>阿里云/腾讯云/本地</td>
                    <td>阿里云/腾讯云/本地</td>
                    <td>阿里云/腾讯云/本地</td>
                </tr>
                <tr>
                    <td>在线支持</td>
                    <td>5×6小时在线支持</td>
                    <td>5×12小时在线支持</td>
                    <td>7×12小时在线支持</td>
                    <td>7×12小时在线支持</td>
                    <td>专属产品经理在线支持</td>
                </tr>
            </table>
        </div>
        <!-- 回到顶部 -->
        <a  href="#top">
            <div class="c-pi-back-top">回到顶部</div>
        </a>
        <div class="c-ss-msg">
            深受桶装水行业各大企业信任
        </div>
        <div class="c-ad-show-wrap">
            <img v-lazy="require('../../../assets/images/ad01.png')" alt="">
            <img v-lazy="require('../../../assets/images/ad02.png')" alt="">
            <img v-lazy="require('../../../assets/images/ad03.png')" alt="">
            <img v-lazy="require('../../../assets/images/ad04.png')" alt="">
            <img v-lazy="require('../../../assets/images/ad05.png')" alt="">
            <img v-lazy="require('../../../assets/images/ad06.png')" alt="">
            <img v-lazy="require('../../../assets/images/ad07.png')" alt="">
        </div>
        <!-- 常见问题 -->
        <div class="c-common-problem">
            <div>常见问题</div>
            <div class="c-cp-piano">
                <el-collapse v-model="activeName" accordion @change="collapseChange()">
                    <el-collapse-item v-for="(item, index) in collapseData" :key="index" :title="item.title" :name="item.id">
                        <div>{{item.content}}</div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <!-- 联系我们 -->
        <ContactUs></ContactUs>
        <!-- footer部分 -->
        <Bottom></Bottom>
        <CustomerService :PropsIsShow="PropsIsShow" v-on:FatherIsShow="FatherIsShow"></CustomerService>
    </div>
</template>
<script>
    import Header from '@/components/Pc/Header';
    import Bottom from '@/components/Pc/Bottom';
    import ContactUs from '@/components/Pc/ContactUs';
    import CustomerService from '@/components/Pc/CustomerService';

    export default {
        name:'SoftwarePurchase',
        components:{
            Header,
            Bottom,
            ContactUs,
            CustomerService
        },
        data(){
            return{
                PropsIsShow: false,
                activeName:'',
                collapseData: [
                    {
                        id: 1,
                        title: '软件到期后还能进行使用吗?数据可以导出吗?',
                        content: '当您购买的基础版本软件使用期限到期后，电脑端的管理软件含APP自带的1-2个账号数量均可永久免费继续使用（新增的APP账号数需继续按年付费使用），不含微信公众号小程序商城，当您到期后微信小程序商城将停止运行，续费后方可继续使用！软件的客户信息数据均可以导出！',
                    },
                    {
                        id: 2,
                        title: '可以开具发票吗?',
                        content: '可以的，请联系客服。',
                    },
                    {
                        id: 3,
                        title: 'APP账号主要干嘛用的？',
                        content: 'APP授权账号主要是给配送师傅使用的，在配送师傅手机上安装个APP，方便日常接单、配送、定位路线规划使用，实时语音提醒配送师傅是否有新订单，并可录入签单现场实际订单空桶财务，如回桶、押桶、欠桶、换桶、还桶、退桶等信息，跟电脑端实时同步方便快捷！',
                    },
                    {
                        id: 4,
                        title: '后续增加分店、APP账号如何计费？',
                        content: '每增加一个新的门店，年费400元/1个，每增加一个APP授权账号，年费200元/1个！无限制版本除外，其它版本需按需付费。',
                    },
                    {
                        id: 5,
                        title: '客户可以网上直接下订单吗？下了订单后订单会在哪里？客户能查看他的财务信息吗？',
                        content: '消费者可以直接通过您提供的公众号小程序商城进行自助下单订水，订单下单成功后会智能分配到配送师傅的手机APP上，并语音告知配送师傅方便接单，电脑端上也可以查看，并进行指派相关配送人员。消费者可以通过小程序个人中心随时查看自己的财务信息，空桶情况，订单信息等',
                    },
                    {
                        id: 6,
                        title: '微信公众号小程序商城能给我带来新客户吗？有什么推广的方案吗？',
                        content: '可以的，我们线上有多款营销工具功能提供给大家使用，比如满减优惠劵、无门槛抵用劵、分享推广、推广赚佣等，我们也会陆续开发并提供市面上比较流行的营销推广工具。',
                    },
                    {
                        id: 7,
                        title: '在软件试用的过程中,如果有功能不能满足您怎么办?',
                        content: '如果您在使用软件的过程中，软件有无法满足您日常使用的情况，您可以跟客服提出您想要的功能，只要是该行业领域内商户普遍需要用到的功能我们多可以免费开发增加，而如是您个人想要的需要您另外付费定制。',
                    },
                ],
            }
        },
        methods:{
            collapseChange(){
              // console.log(this.activeName)
            },

            FatherIsShow(val){
               this.PropsIsShow = val;
            },
            /*
            * 咨询客服
            * */
            toFunctionDownload() {
                this.PropsIsShow = !this.PropsIsShow;
            },
        }
    }
</script>

<style scoped>
    .c-purchase-index-wrap{
        width: 100%;
    }
    .c-pi-wrap{
        width: 45%;
        margin: 0 auto;
        text-align: center;
    }
    .c-pi-wrap>p:nth-child(1){
        font-size: 2.13rem;
        color: #404040;
        margin: 4.57rem 0 2.7rem 0;
    }
    .c-pi-wrap>p:nth-child(2){
        font-size: 1.13rem;
        color: #404040;
    }
    .c-pi-item-wrap{
        width: 64.63%;
        height: 44rem;
        margin: 4.75rem auto 5.8rem auto;
        display: flex;
        flex-direction: row;
    }
    .c-piii-header{
        width: 14.5rem;
        height: 0.31rem;
        background-image: linear-gradient(
                #cacfd3,
                #cacfd3),
        linear-gradient(
                #fff100,
                #fff100);
        background-blend-mode: normal,
        normal;
    }
    .c-year-wrap>span:nth-child(2){
        height: 2rem;
        font-size: 2.5rem;
        color: #404040;
    }
    .c-year-wrap>span{
        display:block;
    }
    .c-pii-item-detail>p:nth-child(1){
        width: 5.7rem;
        font-size: 1.13rem;
        color: #267dff;
        text-align: center;
        margin: 0rem auto 0.88rem auto;
    }
    .c-pii-item-detail>p:nth-child(2){
        width: 9rem;
        font-size: 0.81rem;
        color: #404040;
        margin: 0 auto 2.3rem auto;
        text-align: center;
    }
    .c-pii-item-detail{
        width: 14.5rem;
        height: 42.56rem;
        padding-top: 1rem;
        background-color: #ffffff;
        box-shadow: 0rem 0rem 0.31rem 0rem
        rgba(195, 195, 201, 0.63);
        margin-right: 1rem;

    }
    .c-pii-item-detail{
        width: 14.5rem;
        height: 42.56rem;
        padding-top: 1rem;
        background-color: #ffffff;
        box-shadow: 0rem 0rem 0.31rem 0rem
        rgba(195, 195, 201, 0.63);
        margin-right: 1rem;

    }
    .c-pii-item-detail>p:nth-child(2){
        width: 9rem;
        font-size: 0.81rem;
        color: #404040;
        margin: 0 auto 2.3rem auto;
        text-align: center;
    }
    .c-pii-item-detail>p:nth-child(1){
        width: 5.7rem;
        font-size: 1.13rem;
        color: #267dff;
        text-align: center;
        margin: 0rem auto 0.88rem auto;
    }
    .c-year-wrap>span{
        display:block;
    }
    .c-year-wrap>span:nth-child(2){
        height: 2rem;
        font-size: 2.5rem;
        color: #404040;
    }
    .c-year-wrap>span:nth-child(1){
        width: 0.5rem;
        height: 0.63rem;
        margin-right: .5rem;
        font-size: 0.81rem;
        color: #267dff;
    }
    .c-cp-piano>>>.el-collapse{
        border-top: 0 !important;
    }
    .c-cp-piano>>>.el-collapse-item__header{
        background: #f1f7fc !important;
        /*background: #fff !important;*/
        height: 5.68rem !important;
        text-indent: 1.31rem;
        border-bottom: 1px solid #dcdcdc !important;
    }
    .c-cp-piano>>>.el-collapse-item__header.is-active{
        background: #fff !important;
        /*box-shadow: 0rem 0rem 0.56rem 0rem rgba(10, 27, 81, 0.13) !important;*/
        border-bottom: 0 !important;
    }

    .c-cp-piano>>>.el-collapse-item__content{
         padding-left: 1.31rem !important;
        /*box-shadow: 0rem 0rem 0.56rem 0rem rgba(10, 27, 81, 0.13) !important;*/

    }
    .c-cp-piano>>>.el-collapse-item__warp{
        border: 0;
        /*box-shadow: 0rem 0rem 0.56rem 0rem rgba(10, 27, 81, 0.13) !important;*/
    }
    .c-cp-piano>>>.el-collapse-item__arrow{
        font-size: 1.3rem !important;
        color: #acacac !important;
    }
    .c-common-problem,.c-cp-piano{
        background-color: #f1f7fc;
    }
    .c-common-problem .c-cp-piano{
        width: 51.75rem;
        height: 27.56rem;
        color: #404040;
        margin: 0 auto;
        background-color: #f1f7fc;
    }
    .c-common-problem{
        width: 100%;
        margin-top: 8rem;
        min-height: 56.19rem;
        background-color: #f1f7fc;
    }
    .c-common-problem>div:nth-child(1){
        width: 8.38rem;
        height: 1.88rem;
        margin:0 auto;
        padding:4rem 0;
        font-size: 1.88rem;
        color: #404040;

    }
    .c-ad-show-wrap{
        width: 100%;
        height: 13.56rem;
        white-space:nowrap;
        overflow-x: auto;
        overflow-y:hidden
    }
    .c-ad-show-wrap>img{
        width: 15.44rem;
        height: 11.56rem;
        margin-left:1.3rem;
    }
    .c-ss-msg{
        width: 25.31rem;
        height: 1.88rem;
        font-size: 1.88rem;
        color: #404040;
        margin: 3rem auto;
    }

    /* 表格 */
    .c-pi-back-top{
        width: 6.25rem;
        height: 2.25rem;
        margin: 2rem auto;
        text-align: center;
        line-height: 2.25rem;
        background-color: #ffffff;
        border-radius: 0.31rem;
        border: solid 0.06rem #3396fb;
        font-size: 0.88rem;
        color: #267dff;
    }
    .c-pi-table-wrap{
        width: 64.63%;
        margin: 0 auto;
    }
    .c-pi-table-wrap>table>tr:nth-child(1)>td{
        color: #707070;
        font-size: 1.25rem;
    }
    .c-pi-table-wrap>table>tr>td:nth-child(1){
        font-size: 0.94rem;
        color: #404040;
    }
    .c-pi-table-wrap>table>tr>td{
        font-size: 0.94rem;
        color: #707070;
    }

    .c-pi-table-wrap>table>tr>td{
        height: 3.2rem;
        line-height: 3.2rem;
        font-size: 0.94rem;
        border-bottom: 0.0625rem solid #e5e5e5;
    }
    .c-pi-table-wrap>table>tr>td:last-child{
        text-align: right;
    }
    .c-pi-table-wrap>table{
        width: 100%;
    }

    .c-year-wrap>span:nth-child(3){
        width: 2.88rem;
        height: 1.25rem;
        font-size: 1.25rem;
        color: #267dff;
        margin-top: 1rem;
    }
    .c-year-wrap{
        width: 9.5rem;
        margin: 0 auto;
        /* text-align: center; */
        display: flex;
        flex-direction: row;
        justify-content: center;

    }
    .c-piii-qd{
        width: 9.44rem;
        height: 2.56rem;
        line-height: 2.56rem;
        text-align: center;
        background-image: linear-gradient(#a4a7a9, #a4a7a9), linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        box-shadow: 0rem 0rem 0.31rem 0rem rgba(132, 140, 176, 0.63);
        border-radius: 0.19rem;
        margin: 0 auto;
        color: #ffffff;
        cursor: pointer;
    }
    .c-sites{
        display: flex;
        font-size: 0.88rem;
        height: 4.7rem;
        width: 8.3rem;
        margin: 1.25rem auto 0 auto;
        flex-direction: column;
    }
    .c-sites>div{
        text-align: center;
    }
    .c-sites>p{
        margin: 0 auto;
    }
    .c-pu-line{
        width: 11.5rem;
        height: 0.06rem;
        background-color: #e5e5e5;
        margin: 1rem auto;

    }
    .c-intruduce-wrap{
        width: 10rem;
        height: 13.5rem;
        margin: 0 auto;
        font-size: .5rem;
    }
    .c-intruduce-wrap>p{
        margin-bottom: .6rem;
        text-align: center;
        color: #707070;
    }
    .c-pii-phone{
        width: 10rem;
        margin: 2rem auto;
        font-size: .81rem;
        color: #707070;
    }
    .c-pii-phone>p{
        text-align: center;
        margin-bottom: .5rem;
    }
    .c-pii-item .c-person{
        background-image: linear-gradient(#a4d4f6, #a4d4f6), linear-gradient(#fff100, #fff100);
        background-blend-mode: normal, normal;
    }
    .c-pii-item .c-depend{
        width: 14.5rem;
        height: 0.31rem;
        background-image: linear-gradient(#72c4fe, #72c4fe), linear-gradient(#fff100, #fff100);
        background-blend-mode: normal, normal;
    }
    .c-pii-item-detail .c-free-used{
        width: 9.44rem;
        height: 2.56rem;
        background-image: linear-gradient(-45deg, #5e7cff 0%, #6f96fe 50%, #80affc 100%), linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        box-shadow: 0rem 0rem 0.31rem 0rem
        rgba(132, 140, 176, 0.63);
        border-radius: 0.19rem;
    }
    .c-sites>div:nth-child(1){
        margin-bottom: 1rem;
    }
    .c-price-detail{
        width: 9.5rem;
        height:1.5rem;
        font-size: .81rem;
        line-height: 1.5rem;
        margin: .5rem  auto;
        text-align: center;
    }
    .c-pii-item .c-lx{
        width: 14.5rem;
        height: 0.31rem;
        background-image: linear-gradient(#2190f8, #2190f8), linear-gradient(#fff100, #fff100);
        background-blend-mode: normal, normal;
    }
    .c-three-sites{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .c-pii-item .c-pii-item-detail .c-pd02{
        height: 0.1rem;
    }
    .c-zxgw{
        width: 9.5rem;
        height: 2.56rem;
        line-height: 2.56rem;
        text-align: center;
        margin: 0 auto;
        color: #267dff;
        cursor: pointer;
        background-color: #ffffff;
        box-shadow: 0rem 0rem 0.31rem 0rem rgba(132, 140, 176, 0.63);
        border-radius: 0.19rem;
        border: solid 0.06rem #267dff;
    }

    /* 修饰滚动条 */
    .c-ad-show-wrap::-webkit-scrollbar {
        height:5px;
        background-color:#F5F5F5;
    }
    .c-ad-show-wrap::-webkit-scrollbar-track {
        background-color:#f1f1f1;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius:2em;
    }
    .c-ad-show-wrap::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        -webkit-border-radius: 4em;
        -moz-border-radius: 4em;
        border-radius:4em;
    }
</style>
