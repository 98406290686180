<template>
    <div>
        <div class="download-and-customer-service" @click.stop="isShowFunc()" v-if="isShow">
            <div class="dacs-wrap" @click.stop="preventBubbles()">
                <div class="dacs-wp-t1">立即下载益帮手</div>
                <div class="dacs-wp-t2">
                    <img class="dacs-wpt2-img" v-lazy="require('../../../assets/images/mobile/26.png')" alt="">
                    <div class="dacs-wpt2-t1">下载电脑版本益帮手管理系统</div>
                </div>
                <a href="https://ybsnew888.zhaizhishe.net/pc-download.php">
                    <div class="dacs-wp-t3">
                        <img class="dacs-wpt3-img" v-lazy="require('../../../assets/images/mobile/29.png')" alt="">
                        <div class="dacs-wpt3-t1">立即下载</div>
                    </div>
                </a>
                <div class="dacs-wp-t4">电脑版本可使用电脑端直接打开<br/><a href="https://www.zhaizhishe.com">www.zhaizhishe.com</a>进行下载</div>
                <div class="dacs-wp-t2">
                    <img class="dacs-wpt2-img" v-lazy="require('../../../assets/images/mobile/27.png')" alt="">
                    <div class="dacs-wpt2-t1">下载手机版本益帮手管理系统</div>
                </div>
                <a :href="systemVersion[systemVersionIndex]">
                    <div class="dacs-wp-t3">
                        <img class="dacs-wpt3-img dacs-wpt3-img-cp" v-lazy="require('../../../assets/images/mobile/30.png')" alt="">
                        <div class="dacs-wpt3-t1">立即下载</div>
                    </div>
                </a>
                <div class="dacs-wp-t2">
                    <img class="dacs-wpt2-img" v-lazy="require('../../../assets/images/mobile/28.png')" alt="">
                    <div class="dacs-wpt2-t1">联系客服免费体验益帮手</div>
                </div>
                <div class="dacs-wp-t5">
                    <div class="dacs-wpt5-t1">在线客服：小叶</div>
                    <img @click.stop="customerQrdIsShowFunc(0)" class="dacs-wpt5-img" v-lazy="require('../../../assets/images/mobile/31.png')" alt="">
                </div>
                <div class="dacs-wp-t5">
                    <div class="dacs-wpt5-t1">在线客服：小彭</div>
                    <img @click.stop="customerQrdIsShowFunc(1)" class="dacs-wpt5-img" v-lazy="require('../../../assets/images/mobile/31.png')" alt="">
                </div>
            </div>
            <img class="dacs-img" @click.stop="isShowFunc()" v-lazy="require('../../../assets/images/mobile/25.png')" alt="">
        </div>
        <customerServiceQrd v-on:closeParentDialog="closeChildDialog" :dialogVisible="customerQrdIsShow" :customerServiceData="customerQrdImg[customerQrdImgIndex]"></customerServiceQrd>
    </div>
</template>

<script>
    import customerServiceQrd from '@/components/Mobile/CustomerServiceQrd';
    export default {
        name: "download-and-customer-service",
        components:{
            customerServiceQrd
        },
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        data(){
          return{
              systemVersion: [
                  'https://ybsnew888.zhaizhishe.net/android-download.php', // 安卓
                  'https://itunes.apple.com/us/app/%E7%9B%8A%E5%B8%AE%E6%89%8B-%E6%96%B0%E7%89%88/id1458489949?l=zh&ls=1&mt=8' // 苹果
              ],
              systemVersionIndex: 0, // 默认安卓版本
              customerQrdImg:[  // 客服联系二维码
                  {
                      name: '小叶',
                      img: require('../../../assets/images/customer-service/01.jpg'),
                      phone: '13959286021',
                  },
                  {
                      name: '小彭',
                      img: require('../../../assets/images/customer-service/02.jpg'),
                      phone: '13950188631',
                  },
              ],
              customerQrdImgIndex: 0,   // 客服联系二维码下标
              customerQrdIsShow: false,
          }
        },
        methods:{
            /**
             * 阻止冒泡
             */
            preventBubbles(){
                return;
            },

            /**
             * 传值父级关闭显示
             */
            isShowFunc() {
                this.$emit('DownloadAndCustomerService', false)
            },

            /**
             * 子组件显示
             */
            customerQrdIsShowFunc(val) {
                this.customerQrdImgIndex = val;
                this.customerQrdIsShow = !this.customerQrdIsShow;
                this.$emit('DownloadAndCustomerService', false)
            },
            /**
             * 子组件关闭
             */
            closeChildDialog(val) {
                this.customerQrdIsShow = val;
                this.$emit('DownloadAndCustomerService', true)
            },

            /**
             * 检测登录设备系统
             */
            appSource(){
                const u = navigator.userAgent;
                const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                if (isiOS) {    // ios
                    this.systemVersionIndex = 1;
                } else {    // ad
                    this.systemVersionIndex = 0;
                }
            },
        },

        created(){
            this.appSource()
        },
    }
</script>

<style scoped>
    .download-and-customer-service{
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 100;
        top: 0;
        background: rgba(0,0,0,0.3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .dacs-wrap{
        width: 85%;
        height: 70%;
        overflow: auto;
        background: rgba(255,255,255,0.9);
        box-shadow: 0rem 0.047rem 0.719rem 0rem rgba(0, 0, 0, 0.33);
        border-radius: 0.156rem;
        padding: 0.656rem 0;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: center;*/
    }
    .dacs-img{
        width: 1.438rem;
        height: 1.438rem;
        margin-top: 0.844rem;
    }
    .dacs-wp-t1{
        font-size: 0.531rem;
        letter-spacing: 0.106rem;
        color: #55b0fb;
        text-align: center;
    }
    .dacs-wp-t2{
        display: flex;
        align-items: center;
        margin-top: 0.719rem;
        margin-left: 4%;
    }
    .dacs-wpt2-img{
        width: 0.625rem;
        height: 0.625rem;
    }
    .dacs-wpt2-t1{
        font-size: 0.438rem;
        letter-spacing: 0.088rem;
        color: #404040;
        font-weight: bold;
        margin-left: 0.422rem;
    }
    .dacs-wp-t3{
        width: 3.609rem;
        height: 1.141rem;
        background-image: linear-gradient(-86deg, #418bfa 0%, #55b0fb 100%), linear-gradient(#000000, #000000);
        background-blend-mode: normal, normal;
        border-radius: 0.156rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.453rem;
        margin-left: 16%;
    }
    .dacs-wpt3-img{
        width: 0.563rem;
        height: 0.563rem;
    }
    .dacs-wpt3-img-cp{
        width: 0.563rem;
        height: 0.734rem;
    }
    .dacs-wpt3-t1{
        font-size: 0.438rem;
        letter-spacing: 0.022rem;
        color: #ffffff;
        margin-left: 0.375rem;
    }
    .dacs-wp-t4{
        font-size: 0.406rem;
        color: #707070;
        margin-top: 0.453rem;
        margin-left: 16%;
    }
    .dacs-wp-t4>a{
        text-decoration: underline;
        font-size: 0.406rem;
        color: #267dff;
    }
    .dacs-wp-t5{
        display: flex;
        align-items: center;
        margin-top: 0.688rem;
        margin-left: 16%;
    }
    .dacs-wpt5-t1{
        font-size: 0.438rem;
        letter-spacing: 0.017rem;
        color: #404040;
    }
    .dacs-wpt5-img{
        width: 0.75rem;
        height: 0.75rem;
        margin-left: 0.578rem;
    }
</style>