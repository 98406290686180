<template>
    <div>
        <div class="main13">
            <div class="m13-t1">现在开始使用益帮手管理系统</div>
            <div class="m13-t2">益帮手是一款非常容易上手，从第一笔订单开始，你将获得更舒适的使用体验。</div>
            <div class="m13-t3" @click="DownloadAndCustomerServiceIsShow=true">下载APP</div>
        </div>
        <div class="bottom">
            <div class="bm-t">联系我们</div>
            <div class="bm-m">
                <div class="bm-m-l" v-for="(item, index) in customerQrdImg" @click="contactUs(index)">
                    <span class="bm-ml-t1">在线客服：{{item.name}}</span>
                    <img class="bm-ml-img" v-lazy="require('../../../assets/images/mobile/24.png')" alt="">
                </div>
            </div>
            <div class="bm-b">
                <div class="bm-b-t1">咨询热线：<a class="bm-b-t1" href="tel:0592-5793363‬">0592-5793363</a></div>
                <div class="bm-b-t2">厦门宅职社网络科技有限公司</div>
                <div class="bm-b-t3">厦门市思明区宜兰路99号海峡明珠广场4层410-1B单元</div>
                <div class="btm-l-t3">@2024 宅职社·
                    <a class = "beianhao" rel="nofollow" href="https://beian.miit.gov.cn/" target="_blank" style = "color: #ffffff">闽ICP备18012019号</a>
                </div>
            </div>
        </div>
        <customerServiceQrd v-on:closeParentDialog="closeCustomerServiceQrdDialog" :dialogVisible="customerServiceQrdIsShow" :customerServiceData="customerQrdImg[customerQrdImgIndex]"></customerServiceQrd>
        <downloadAndCustomerService v-on:DownloadAndCustomerService="closeDownloadAndCustomerService" :isShow="DownloadAndCustomerServiceIsShow"></downloadAndCustomerService>
    </div>
</template>

<script>
    import customerServiceQrd from '@/components/Mobile/CustomerServiceQrd';
    import downloadAndCustomerService from '@/components/Mobile/DownloadAndCustomerService';

    export default {
        name: "Bottom",
        components:{
            customerServiceQrd,
            downloadAndCustomerService
        },
        data(){
            return{
                DownloadAndCustomerServiceIsShow: false, // 下载和客服弹窗
                customerServiceQrdIsShow: false, // 客服二维码弹窗
                customerQrdImg:[  // 客服联系二维码
                    {
                        name: '小谢',
                        img: require('../../../assets/images/xiaoxie.jpeg'),
                        phone: '13950188631',
                    },
                    {
                        name: '小沈',
                        img: require('../../../assets/images/customer-service/01.jpg'),
                        phone: '13959286021',
                    },
                    {
                        name: '小易',
                        img: require('../../../assets/images/function-download/38.png'),
                        phone: '18959935543',
                    },

                ],
                customerQrdImgIndex: 0,   // 客服联系二维码下标
            }
        },
        created: function() {
            // let change = Math.round(Math.random());
            // if(change) {
            //     [this.customerQrdImg[0],this.customerQrdImg[1]] = [this.customerQrdImg[1],this.customerQrdImg[0]];
            // }
        },
        methods:{

            // 关闭客服与下载弹窗
            closeDownloadAndCustomerService(val){
                this.DownloadAndCustomerServiceIsShow = val;
            },
            /**
             * 显示客服二维码
             * @param val
             */
            contactUs(val){
                this.customerQrdImgIndex = val;
                this.customerServiceQrdIsShow = true;
            },

            /**
             * 关闭客服二维码
             * @param val
             */
            closeCustomerServiceQrdDialog(val) {
                this.customerServiceQrdIsShow = val;
            },
        }
    }
</script>

<style scoped>
    .main13{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: #fff;
    }
    .m13-t1{
        font-size: 0.688rem;
        font-weight: bold;
        color: #404040;
        margin-top: 1.031rem;
    }
    .m13-t2{
        font-size: 0.438rem;
        color: #707070;
        text-align: center;
        margin-top: 0.781rem;
    }
    .m13-t3{
        width: 4.609rem;
        height: 1.266rem;
        background: url("../../../assets/images/mobile/23.png");
        background-size: 100% 100%;
        line-height: 1.266rem;
        font-size: 0.531rem;
        color: #ffffff;
        text-align: center;
        margin: 1.031rem 0;
    }
    .bottom{
        width: 100%;
        background-color: #434343;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.672rem 0 0.969rem 0;
    }
    .bm-t{
        font-size: .5rem;
        letter-spacing: 0.04rem;
        color: #ffffff;
    }
    .bm-m{
        width: 88%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .bm-m-l{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .563rem;
    }
    .bm-ml-t1{
        font-size: 0.438rem;
        color: #ffffff;
    }
    .bm-ml-img{
        width: 0.75rem;
        height: 0.75rem;
        margin-left: .297rem;
    }
    .bm-b{
        width: 93%;
        height: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-top: 1px solid #dedede;
        margin-top: 1.078rem;
        padding-top: 0.438rem;
    }
    .bm-b>div{
        font-size: 0.406rem;
        letter-spacing: 0.016rem;
        margin-top: 0.234rem;
    }
    .bm-b-t1{
        color: #fff;
    }
    .beianhao:hover{
        text-decoration: underline;
    }
</style>
