<template>
    <div class="horse-light">
        <div class="hlt-t1">{{cTitle}}</div>
        <div class="hlt-wrap">
            <template>
                <el-carousel :interval="4000" type="card" loop :height="bannerHeight+'px'">
                    <el-carousel-item v-for="item in EnteringMerchant" :key="item">
                        <el-row >
                            <el-col :span="24">
                                <img class="hlt-wrap-img" ref="bannerHeight" v-lazy="item" @load="imgLoad" alt="">
                            </el-col>
                        </el-row>
                    </el-carousel-item>
                </el-carousel>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props:[
            'cTitle'
        ],
        name: "Horselight",
        data(){
            return{
                bannerHeight:'',
                EnteringMerchant:[  // 入驻商户LOGO
                    require('../../../assets/images/ad001.png'),
                    require('../../../assets/images/ad02.png'),
                    require('../../../assets/images/ad03.png'),
                    require('../../../assets/images/ad04.png'),
                    require('../../../assets/images/ad05.png'),
                    require('../../../assets/images/ad06.png'),
                    require('../../../assets/images/ad07.png'),
                    require('../../../assets/images/ad08.png'),
                ],
            }
        },
        methods:{
            imgLoad(){
                this.$nextTick(()=>{
                    this.bannerHeight = this.$refs.bannerHeight[0].height;
                })
            },
        },

        mounted(){
            this.imgLoad();
            window.addEventListener('resize', () => {
                this.bannerHeight = this.$refs.bannerHeight[0].height;
                this.imgLoad();
            }, false)
        }
    }
</script>

<style scoped>
    .horse-light{
        width: 100%;
        min-height: 5rem;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.672rem 0;
    }
    .hlt-t1{
        font-size: 0.688rem;
        color: #000;
    }
    .hlt-wrap{
        width: 100%;
        height: 50%;
        margin-top: 1.672rem;
    }
    .hlt-wrap-img{
        width: 100%;
    }
</style>