<template>
    <div>
        <div class="bottom">
            <div class="btm-l">
                <div class="btm-l-t1">联系我们</div>
                <div class="btm-l-t2">‭0592-5793363‬</div>
                <div class="btm-l-t3">
                    <span>在线客服：{{customerQrdImg[0]['name']}}</span>
                    <span class="btm-l-t3-t1" @click='advisoryService(0)'>咨询客服</span>
                    <span style="margin-left: 2rem;">在线客服：{{customerQrdImg[1]['name']}}</span>
                    <span class="btm-l-t3-t1" @click='advisoryService(1)'>咨询客服</span>
                </div>
                <div class="btm-l-t3">
                    <span>在线客服：小易</span>
                    <span class="btm-l-t3-t1" @click='advisoryService(2)'>咨询客服</span>
                </div>
                <div class="btm-l-t3">地址：厦门市思明区宜兰路99号海峡明珠广场4层410-1B单元</div>
                <div class="btm-l-t3">@2024 宅职社·
                    <a class = "beianhao" rel="nofollow" href="https://beian.miit.gov.cn/" target="_blank" style = "color: #ffffff">闽ICP备18012019号</a>
                </div>
            </div>
            <div class="btm-r">
                <div class="btm-r-t">
                    <img class="btm-r-t-img" v-lazy="require('../../../assets/images/function-download/35.png')" alt="">
                    <span>扫一扫关注微信公众号</span>
                </div>
                <img class="btm-r-b" v-lazy="require('../../../assets/images/function-download/34.png')"/>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="show-qrcode">
            <el-dialog
                    :visible.sync="dialogVisible"
                    width="27.81rem" >
                <div class="qrcode-t">
                    <div class="qrcode-t-w1">
                        <img v-lazy="require('../../../assets/images/function-download/36.png')" height="34" width="115"/>
                        <img @click="dialogVisible=false" v-lazy="require('../../../assets/images/function-download/37.png')" height="20" width="20"/>
                    </div>
                    <div class="qrcode-t-w2">客户经理：{{customerQrdImg[customerQrdImgIndex].name}}</div>
                    <div class="qrcode-t-w3">
                        <img v-lazy="customerQrdImg[customerQrdImgIndex].img" height="204" width="204"/>
                    </div>
                    <div class="qrcode-t-w4">
                        <img v-lazy="require('../../../assets/images/function-download/39.png')" height="29" width="30"/>
                        <span>微信扫码联系客服经理</span>
                    </div>
                </div>
                <div class="qrcode-b">
                    联系电话：{{customerQrdImg[customerQrdImgIndex].phone}}
                    <br/>
                    <br/>
                    添加客户经理微信号：{{customerQrdImg[customerQrdImgIndex].phone}} 了解疑问
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bottom",
        data(){
          return{
              dialogVisible: false,
              customerQrdImg:[  // 客服联系二维码
                  {
                      name: '小谢',
                      img: require('../../../assets/images/xiaoxie.jpeg'),
                      phone: '13950188631',
                  },
                  {
                      name: '小沈',
                      img: require('../../../assets/images/customer-service/01.jpg'),
                      phone: '13959286021',
                  },
                  {
                      name: '小易',
                      img: require('../../../assets/images/function-download/38.png'),
                      phone: '18959935543',
                  },

              ],
              customerQrdImgIndex: 0,   // 客服联系二维码下标
          }
        },
        created: function() {
            // let change = Math.round(Math.random());
            // if(change) {
            //     [this.customerQrdImg[0],this.customerQrdImg[1]] = [this.customerQrdImg[1],this.customerQrdImg[0]];
            // }
            console.log(this.customerQrdImg, change);
        },
        methods:{
            /*
           * 咨询客服
           * */
            advisoryService(i) {
                this.customerQrdImgIndex = i;
                this.dialogVisible = true
            },
        }
    }
</script>

<style scoped>
    .bottom{
        height: 27.06rem;
        background: #303030;
        display: flex;
        padding: 0 24.25rem;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
    }
    .btm-l{
        width: 50rem;
        /*margin-right: 23.44rem;*/
    }
    .btm-l-t1{
        font-size: 1.56rem;
        letter-spacing: 0.06rem;
    }
    .btm-l-t2{
        font-size: 1.56rem;
        letter-spacing: 0.06rem;
        margin-top: 1.94rem;
    }
    .btm-l-t3{
        font-size: 1.25rem;
        letter-spacing: 0.05rem;
        margin-top: 1.94rem;
    }
    .btm-l-t3-t1{
        display: inline-block;
        margin-left: 3rem;
        width: 7.5rem;
        text-align: center;
        border-radius: 1rem;
        height: 2rem;
        line-height: 2rem;
        background-image: linear-gradient(91deg, #46a1e8 0%, #4683ed 50%, #4667f2 100%), linear-gradient(#e5e5e5, #e5e5e5);
        background-blend-mode: normal, normal;
        letter-spacing: 0.04rem;
        cursor: pointer;
    }
    .btm-r{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btm-r-t{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        letter-spacing: 0.03rem;
    }
    .btm-r-t-img{
        width: 1.5rem;
        height: 1.44rem;
        margin-right: 0.69rem;
    }
    .btm-r-b{
        margin-top: 1.81rem;
        width: 13.19rem;
        height: 13.19rem;
    }
    /* 弹窗 */

    .show-qrcode>>>.el-dialog{
        border-radius: 1.5rem;
    }
    .show-qrcode>>>.el-dialog__body{
        padding: 0;
    }
    .show-qrcode>>>.el-dialog__header{
        display: none;
    }

    .qrcode-t{
        padding: 30px 20px 0 20px;
        height: 29rem;
    }
    .qrcode-t-w1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .qrcode-t-w2{
        width: 17.94rem;
        height: 2.13rem;
        background-color: #f7f7f7;
        border-radius: 1.06rem;
        font-size: 1.3rem;
        line-height: 2.13rem;
        color: #404040;
        margin: 1.91rem auto 0;
        text-align: center;
    }
    .qrcode-t-w3{
        text-align: center;
        margin-top: 1rem;
    }
    .qrcode-t-w4{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.38rem;
        letter-spacing: 0.07rem;
        color: #404040;
    }
    .qrcode-t-w4>span{
        margin-left: 1rem;
    }
    .qrcode-b{
        height: 5.63rem;
        font-size: 1.3rem;
        background-color: #f7f7f7;
        color: #404040;
        border-radius: 0 0 1.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* 弹窗 */
    .beianhao:hover{
        text-decoration: underline;
    }
</style>
