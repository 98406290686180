<template>
    <div style="display: none">
        <img class="nav-icon" v-lazy="require('../../../assets/images/mobile/nav-icon.png')" alt="" @click="isShowNavFunc">
        <div class="nav" :style="{height: screenHeight+'px', background: isShowNav?'rgba(0,0,0,0.8)':'rgba(0,0,0,0)',zIndex:isShowNav?100:-10}">
            <div class="nav-l" :style="{left: isShowNav?0:-screenWidth+'px'}">
                <div class="nav-l-t1" @click="toFunctionDownload()">首页</div>
                <div class="nav-l-t1" @click="toAgentJoining()">代理加盟</div>
                <div class="nav-l-t1" @click="toSoftwarePurchase()">软件购买</div>
            </div>

            <div class="nav-r" @click="isShowNavFunc">
                <img class="nav-r-img" v-lazy="require('../../../assets/images/mobile/close.png')" :style="{opacity: isShowNav?1:0}" >
            </div>
        </div>
    </div>
</template>

<script>
    import 'lib-flexible';
    export default {
        name: "Header",
        data(){
            return{
                screenHeight: window.innerHeight, // 屏幕高度尺寸
                screenWidth: window.innerWidth, // 屏幕宽度尺寸
                isShowNav: false, // 控制导航栏是否显示
                isShowNavCopy: false, // 辅助  控制导航栏是否显示
            }
        },
        methods:{
            /**
             * 首页
             */
            toFunctionDownload() {
                this.$router.push({
                    path: '/'
                });
            },

             /**
             * 跳转代理加盟
             */
            toAgentJoining() {
                this.$router.push({
                    path: '/agent-joining'
                });
            },

            /**
             * 跳转软件购买
             */
            toSoftwarePurchase() {
                this.$router.push({
                    path: '/software-purchase'
                });
            },

            /**
             * 导航栏展示/关闭
             */
            isShowNavFunc() {
                this.isShowNav = !this.isShowNav;
            }
        }
    }
</script>

<style scoped>
    .nav-icon{
        width: 0.875rem;
        height: 0.875rem;
        position: fixed;
        left: 0.625rem;
        top: 0.625rem;
        z-index: 50;
    }
    .nav{
        width: 100%;
        position: fixed;
        z-index: 100;
        background: rgba(0,0,0,0.8);
        display: flex;
        transition: all 0.5s;
    }
    .nav-l{
        flex:3;
        background: #0B0D19;
        position: relative;
        left: 0;
        transition: all 0.5s;

    }
    .nav-l-t1{
        margin: 0 .5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        text-indent: .5rem;
        color: #fff;
        font-size: .5rem;
        border-bottom: 1px solid #232530;
    }
    .nav-r{
        flex: 1;
        text-align: center;
    }
    .nav-r-img{
        margin-top: .8rem;
        width: .5rem;
        height: .5rem;
        transition: all 0.5s;
    }

</style>
