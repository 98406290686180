<template>
    <div>
        <div class="header">
            <div class="sub-header">
                <img class="header-logo" v-lazy="require('../../../assets/images/logo_deepblue.png')" alt="">
                <nav class="nav">
                    <a v-for="val in navData" :key="val.id" @click="toSwitch(val.id)" :class="navDataId == val.id?'subnav active':'subnav'">{{val.name}}</a>
                    <!--<a class='nav-login subnav'>登录</a>-->
                    <!--<a class='nav-registered'>立即注册</a>-->
                </nav>
            </div>
        </div>
        <div class="header-substitute"></div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data(){
          return{
              navDataId: 1,
              navData:[
                  // {
                  //     id: 1,
                  //     name: '首页',
                  //     click: 'toHome'
                  // },
                  {
                      id: 2,
                      // name: '功能与下载',
                      name: '首页',
                      click: 'toFunctionDownload'
                  },
                  {
                      id: 3,
                      name: '软件购买',
                      click: 'toSoftwarePurchase'
                  },
                  {
                      id: 4,
                      name: '代理加盟',
                      click: 'toAgentJoining'
                  },
                  {
                      id: 5,
                      name: '服务与支持',
                      click: 'toServiceSupport'
                  },
              ],
          }
        },

        methods:{
            toSwitch(id) {
                this.navDataId = id;
                switch(id){
                    case 1:
                        this.$router.push({
                            path: '/home'
                        });
                        break;
                    case 2:
                        this.$router.push({
                            path: '/'
                        });
                        break;
                    case 3:
                        this.$router.push({
                            path: '/software-purchase'
                        });
                        break;
                    case 4:
                        this.$router.push({
                            path: '/agent-joining'
                        });
                        break;
                    case 5:
                        this.$router.push({
                            path:'./service-support'
                        });
                        break;
                    default:
                        break;
                }
            }
        },
         created(){
             switch(this.$route.name){
                 case 'home':
                     this.navDataId = 1;
                     break;
                 case 'function-download':
                     this.navDataId = 2;
                     break;
                 case 'software-purchase':
                     this.navDataId = 3;
                     break;
                 case 'agent-joining':
                     this.navDataId = 4;
                     break;
                 case 'service-support':
                     this.navDataId = 5;
                     break;
                 default:
                     this.navDataId = 1;
                     break;
             }
         }
    }
</script>

<style scoped>
    .header-substitute{
        height: 3.75rem;
    }
    .header{
        width:100%;
        height: 3.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        position: fixed;
        z-index:999;
        box-shadow: rgba(149, 149, 149, 0.35) 0 0 10px;
    }
    .sub-header{
        width:101rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header-logo{
    }
    .nav{
        display: inline-block;
        width:auto;
        vertical-align: middle;
    }
    .subnav{
        font-size: 1rem;
        color: #000;
        margin:0 0 0 3rem;
        cursor: pointer;
    }
    .subnav:last-of-type{
        margin-right: 18rem;
    }
    .subnav.active{
        color: #4168fb;
    }
    .nav-login, .nav-registered{
        display: inline-block;
        width: 6.25rem;
        height: 2.25rem;
        box-shadow: 0rem 0rem 0.44rem 0rem rgba(71, 108, 241, 0.35);
        border-radius: 1.13rem;
        font-size: 1rem;
        line-height: 2.25rem;
        text-align: center;
    }
    .nav-login{
        background-color: #ffffff;
        border: solid 0.06rem #267dff;
        color: #2b80ff;
    }
    .nav-registered{
        background-image: linear-gradient(90deg, #4168fb 0%, #4c7aed 100%), linear-gradient(#dcdcdc, #dcdcdc);
        border: solid 0.06rem #4168fb;
        margin-left: 1.06rem;
        color: #ffffff;
        cursor: pointer;
    }
</style>