<template>
    <div>
        <Header></Header>
        <div class="main1" :style="{height:screenHeight+'px'}">
            <img class="m1-img1" v-lazy="require('../../../assets/images/mobile/fill_logo.png')" alt="">
            <div class="m1-t1">全新2.0版全新上线</div>
            <div class="m1-t2">一套软件帮您解决所有问题<br/>马上下载让您经营管理更加高效</div>
            <!--<div class="m1-wrap">-->
                <img class="m1-img2" v-lazy="require('../../../assets/images/mobile/01.png')" alt="">
                <div class="m1-t3" @click="DownloadAndCustomerServiceIsShowFunc">立即下载益帮手手机版</div>
            <!--</div>-->
        </div>

        <div :class="isBackgroundImage == 1?'main2 fill-before':'main2'" :style="{height:screenHeight+'px'}">
            <div class="m2-t1">益帮手，是跨越<br/>用手机用电脑都可以</div>
        </div>

        <div class="main3" :style="{height:screenHeight+'px'}">
            <div class="m3-t1">多种设备完美支持</div>
            <div class="m3-t2">唯有赶上进入移动时代，享受掌上管理，<br/>才是真正的便捷</div>
            <div class="m3-wrap">
                <div class="m3-wp-l">
                    <img class="m3-wpl-img1" v-lazy="require('../../../assets/images/mobile/04.png')" alt="">
                    <div class="m3-wpl-t1">电脑版</div>
                </div>
                <div class="m3-wp-t1"></div>
                <div class="m3-wp-r">
                    <img class="m3-wpr-img1" v-lazy="require('../../../assets/images/mobile/05.png')" alt="">
                    <div class="m3-wpr-t1">手机版</div>
                </div>
            </div>
            <div class="m3-t3">支持电脑、苹果手机、安卓手机三端联动<br/>您不仅可以使用电脑端管理水店<br/>也可以随时随地的使用手机管理</div>
            <img class="m3-img1" v-lazy="require('../../../assets/images/mobile/03.png')" alt="">
        </div>

        <div :class="isBackgroundImage == 2?'main4 fill-before':'main4'" :style="{height:screenHeight+'px'}">
            <div class="m4-t1">益帮手，是随时随地<br/>自助下单</div>
        </div>

        <div class="main5" :style="{height:screenHeight+'px'}">
            <div class="m5-t1">微信小程序商城</div>
            <div class="m5-t2">微信小程序作为如今最便捷的应用，是您<br/>最值得拥有的工具</div>
            <div class="m5-wrap">
                <div class="m5-wp-l">
                    <img class="m5-wpl-img1" v-lazy="require('../../../assets/images/mobile/07.png')" alt="">
                    <div class="m5-wpl-t1">手机支付</div>
                </div>
                <div class="m5-wp-t1"></div>
                <div class="m5-wp-r">
                    <img class="m5-wpr-img1" v-lazy="require('../../../assets/images/mobile/08.png')" alt="">
                    <div class="m5-wpr-t1">移动商城</div>
                </div>
                <div class="m5-wp-t1"></div>
                <div class="m5-wp-r">
                    <img class="m5-wpr-img1" v-lazy="require('../../../assets/images/mobile/09.png')" alt="">
                    <div class="m5-wpr-t1">营销工具</div>
                </div>
            </div>
            <div class="m5-t3">通过小程序商城自主下单，<br/>接单与财务核算都不再是难题，<br/>更有营销工具配合，<br/>客户也能源源不断的主动上门。</div>
            <img class="m5-img1" v-lazy="require('../../../assets/images/mobile/06.png')" alt="">
        </div>

        <div :class="isBackgroundImage == 3?'main6 fill-before':'main6'" :style="{height:screenHeight+'px'}">
            <div class="m6-t1">益帮手，是无论你在哪<br/>都可以心系门店</div>
        </div>

        <div class="main7" :style="{height:screenHeight+'px'}">
            <div class="m7-t1">手机管理后台</div>
            <div class="m7-t2">唯有享受便捷，轻松做老板不再是难事</div>
            <div class="m7-wrap">
                <div class="m7-wp-l">
                    <img class="m7-wpl-img1" v-lazy="require('../../../assets/images/mobile/10.png')" alt="">
                    <div class="m7-wpl-t1">订单管理</div>
                </div>
                <div class="m7-wp-t1"></div>
                <div class="m7-wp-r">
                    <img class="m7-wpr-img1" v-lazy="require('../../../assets/images/mobile/11.png')" alt="">
                    <div class="m7-wpr-t1">配送管理</div>
                </div>
                <div class="m7-wp-t1"></div>
                <div class="m7-wp-r">
                    <img class="m7-wpr-img1" v-lazy="require('../../../assets/images/mobile/09.png')" alt="">
                    <div class="m7-wpr-t1">营销工具</div>
                </div>
                <div class="m7-wp-t1"></div>
                <div class="m7-wp-r">
                    <img class="m7-wpr-img1" v-lazy="require('../../../assets/images/mobile/12.png')" alt="">
                    <div class="m7-wpr-t1">财务管理</div>
                </div>
            </div>
            <div class="m7-t3">手机也能有一款功能齐全的管理后台<br/>创建订单、接单、回单、管理商品、查看财务都可以<br/>甚至你想布置一些营销活动，它统统都可以办到</div>
            <img class="m7-img1" v-lazy="require('../../../assets/images/mobile/13.png')" alt="">
        </div>

        <div :class="isBackgroundImage == 4?'main8 fill-before':'main8'" :style="{height:screenHeight+'px'}">
            <div class="m8-t1">益帮手，是便捷<br/>省心的操作体验 </div>
        </div>

        <div class="main9" :style="{height:screenHeight+'px'}">
            <div class="m9-t1">给您最好用的订单系统</div>
            <div class="m9-t2">益帮手会竭尽所能给您带来最好的派单体验</div>
            <div class="m9-wrap">
                <div class="m9-wp-l">
                    <img class="m9-wpl-img1" v-lazy="require('../../../assets/images/mobile/14.png')" alt="">
                    <div class="m9-wpl-t1">异常标签</div>
                </div>
                <div class="m9-wp-t1"></div>
                <div class="m9-wp-r">
                    <img class="m9-wpr-img1" v-lazy="require('../../../assets/images/mobile/15.png')" alt="">
                    <div class="m9-wpr-t1">订单自动分配</div>
                </div>
                <div class="m9-wp-t1"></div>
                <div class="m9-wp-r">
                    <img class="m9-wpr-img1" v-lazy="require('../../../assets/images/mobile/16.png')" alt="">
                    <div class="m9-wpr-t1">来电弹窗</div>
                </div>
            </div>
            <div class="m9-t3">无论是电话下单还是小程序下单，<br/>我们都可以给您带来最好的用户体验。<br/>订单自动分配。无异常订单无需人工分配，<br/>益帮手给您带来的是最简便高效的订单系统。</div>
            <img class="m9-img1" v-lazy="require('../../../assets/images/mobile/17.png')" alt="">
        </div>

        <div :class="isBackgroundImage == 5?'main10 fill-before':'main10'" :style="{height:screenHeight+'px'}">
            <div class="m10-t1">益帮手，是贴心<br/>给您带来丰富的营销工具 </div>
        </div>

        <div class="main11" :style="{height:screenHeight+'px'}">
            <div class="m11-t1">营销推广工具</div>
            <div class="m11-t2">有效的组合与使用营销手段， <br/>客户还不是源源不断的来</div>
            <div class="m11-wrap">
                <div class="m11-wp-l">
                    <img class="m11-wpl-img1" v-lazy="require('../../../assets/images/mobile/18.png')" alt="">
                    <div class="m11-wpl-t1">异常标签</div>
                </div>
                <div class="m11-wp-t1"></div>
                <div class="m11-wp-r">
                    <img class="m11-wpr-img1" v-lazy="require('../../../assets/images/mobile/19.png')" alt="">
                    <div class="m11-wpr-t1">订单自动分配</div>
                </div>
                <div class="m11-wp-t1"></div>
                <div class="m11-wp-r">
                    <img class="m11-wpr-img1" v-lazy="require('../../../assets/images/mobile/20.png')" alt="">
                    <div class="m11-wpr-t1">来电弹窗</div>
                </div>
            </div>
            <div class="m11-t3">益帮手为您准备了数十个营销推广方案<br/>营销工具可以有效的拉新推广、留存客户、提高转换等<br/>如果您想大展身手，营销工具将成为您生意场的好帮手</div>
            <img class="m11-img1" v-lazy="require('../../../assets/images/mobile/21.png')" alt="">
        </div>
        <img class="main12" v-lazy="require('../../../assets/images/mobile/22.png')">
        <Bottom></Bottom>
        <CustomerService></CustomerService>
        <DownloadAndCustomerService v-on:DownloadAndCustomerService="DownloadAndCustomerServiceClose" :isShow="DownloadAndCustomerServiceIsShow"></DownloadAndCustomerService>
    </div>
</template>

<script>
    import Bottom from '@/components/Mobile/Bottom';
    import Header from '@/components/Mobile/Header';
    import CustomerService from '@/components/Mobile/CustomerService';
    import DownloadAndCustomerService from '@/components/Mobile/DownloadAndCustomerService';
    export default {
        name: "FunctionDownload",
        components:{
            Bottom,
            Header,
            DownloadAndCustomerService,
            CustomerService
        },
        data(){
            return{
                screenHeight: document.documentElement.clientHeight, // 屏幕尺寸
                isBackgroundImage:1, // 背景图可见
                DownloadAndCustomerServiceIsShow: false,
            }
        },
        methods:{

            /**
             * 显示下载和客服
             */
            DownloadAndCustomerServiceIsShowFunc(){
                this.DownloadAndCustomerServiceIsShow = !this.DownloadAndCustomerServiceIsShow
            },
            /**
             * 关闭下载与客观子组件
             */
            DownloadAndCustomerServiceClose(val) {
                this.DownloadAndCustomerServiceIsShow = val;
            },

            handleScroll () { //改变元素#searchBar的top值
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                scrollTop = scrollTop/document.documentElement.clientHeight;
                if (scrollTop >= 0 && scrollTop < 2) {
                    this.isBackgroundImage = 1;
                }else if(scrollTop >= 2 && scrollTop < 4) {
                    this.isBackgroundImage = 2;
                }else if(scrollTop >= 4 && scrollTop < 6) {
                    this.isBackgroundImage = 3;
                }else if(scrollTop >= 6 && scrollTop < 8) {
                    this.isBackgroundImage = 4;
                }else if(scrollTop >= 6 && scrollTop < 10) {
                    this.isBackgroundImage = 5;
                }else {
                    this.isBackgroundImage = 1;
                }
            },
        },

        mounted () {//给window添加一个滚动滚动监听事件
            window.addEventListener('scroll', this.handleScroll)
        },
        destroyed () {//离开该页面需要移除这个监听的事件
            window.removeEventListener('scroll', this.handleScroll)
        },

    }
</script>

<style scoped>
    .main1{
        width: 100%;
        background: url("../../../assets/images/mobile/main1.jpg");
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .m1-img1{
        width: 50%;
        height: auto;
        margin-top: 0.922rem;
    }
    .m1-t1{
        font-size: 0.834rem;
        color: #ffffff;
        margin-top: 1.047rem;
    }
    .m1-t2{
        font-size: 0.438rem;
        letter-spacing: 0.022rem;
        color: #ffffff;
        text-align: center;
        margin-top: 0.625rem;
    }
    .m1-img2{
        width: 90%;
    }
    .m1-t3{
        display: block;
        width: 6.406rem;
        height: 1.391rem;
        line-height: 1.391rem;
        background-color: #ffffff;
        box-shadow: 0rem 0rem 0.28rem 0rem rgba(47, 56, 233, 0.19);
        border-radius: 1.39rem;
        color: #428dfa;
        text-align: center;
        position:relative;
        bottom: .5rem;
        font-size: 0.5rem;
    }

    .main2,.main4, .main6, .main8, .main10{
        /*background: url('../../../assets/images/mobile/main2.jpg') center 0 no-repeat;*/
        /*background-size: cover;*/
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.547rem;
        letter-spacing: 0.109rem;
        color: #ffffff;
        width: 100%;
        /*background-image:url('../../../assets/images/mobile/main2.jpg');*/
        /*background-repeat: no-repeat;*/
        /*background-position: center;*/
        /*background-attachment: fixed;*/

    }
    .fill-before:before{
        content:'';
    }
    .main2:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main2.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main4:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main3.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main6:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main4.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main8:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main5.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main10:before {
         position: fixed;
         z-index: -1;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
         background: url('../../../assets/images/mobile/main6.jpg') center 0 no-repeat;
         background-size: cover;
     }

    .m2-t1, .m4-t1, .m6-t1, .m8-t1, .m10-t1{
        text-align: center;
        line-height: 1rem;
    }
    .main3, .main5, .main7, .main9, .main11{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
    }
    .m3-t1,.m5-t1,.m7-t1,.m9-t1,.m11-t1{
        font-size: 0.75rem;
        letter-spacing: 0.075rem;
        color: #404040;
    }
    .m3-t2,.m5-t2,.m7-t2,.m9-t2,.m11-t2{
        font-size: 0.375rem;
        line-height: .7rem;
        letter-spacing: 0.038rem;
        color: #707070;
        margin-top: 0.516rem;
        text-align: center;
    }
    .m3-wrap,.m5-wrap,.m7-wrap,.m9-wrap,.m11-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.484rem;
    }
    .m3-wp-l, .m3-wp-r,.m5-wp-l, .m5-wp-r,.m7-wp-l, .m7-wp-r,.m9-wp-l, .m9-wp-r,.m11-wp-l, .m11-wp-r{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #707070;
        letter-spacing: 0.2rem;
    }
    .m3-wpl-img1, .m5-wpl-img1, .m7-wpl-img1, .m9-wpl-img1, .m11-wpl-img1{
        width: 1.266rem;
        height: 1.25rem;
    }
    .m3-wp-t1,.m5-wp-t1,.m7-wp-t1,.m9-wp-t1,.m11-wp-t1{
        height: 75%;
        border-left: 1px solid #dcdcdc;
        margin: 0 0.516rem 0;
    }
    .m3-wpr-img1,.m5-wpr-img1,.m7-wpr-img1,.m9-wpr-img1,.m11-wpr-img1{
        width: 1.266rem;
        height: 1.25rem;
    }
    .m3-wpr-t1, .m3-wpl-t1,.m5-wpr-t1, .m5-wpl-t1,.m7-wpr-t1, .m7-wpl-t1,.m9-wpr-t1, .m9-wpl-t1,.m11-wpr-t1, .m11-wpl-t1{
        font-size: 0.294rem;
        letter-spacing: 0.059rem;
        color: #707070;
    }
    .m3-t3, .m5-t3, .m7-t3, .m9-t3, .m11-t3{
        font-size: 0.406rem;
        text-align: center;
        line-height: 0.697rem;
        font-weight: bold;
        margin-top: 1rem;
    }
    .m3-img1, .m5-img1, .m7-img1, .m9-img1, .m11-img1{
        width: 92%;
        margin-top: 1.313rem;
    }
    /*.main4{*/
        /*background: url("../../../assets/images/mobile/main3.jpg") fixed;*/
        /*background-size: cover;*/
     /*}*/
    .main5,.main9,.main11{
        justify-content: space-between;
    }
    .m5-t1,.m9-t1,.m11-t1{
        margin-top: .844rem;
    }
    .m5-img1,.m9-img1,.m11-img1{
        width: 65%;
    }
    /*.main6{*/
        /*background: url("../../../assets/images/mobile/main4.jpg") fixed;*/
        /*background-size: cover;*/
    /*}*/
    /*.main8{*/
        /*background: url("../../../assets/images/mobile/main5.jpg") fixed;*/
        /*background-size: cover;*/
    /*}*/
    /*.main10{*/
        /*background: url("../../../assets/images/mobile/main6.jpg") fixed;*/
        /*background-size: cover;*/
    /*}*/
    .main12{
        width: 100%;
        height: auto;
        display: flex;
    }
</style>
