<template>
<div class="c-login-views-wrap">

  <p>欢迎登录益帮手</p>
  <p>管理更加轻松，携手共掘千万市场</p>
  <div class="c-login-logo">
    <img src="../assets/images/denlulogo.png" alt="">
  </div>
  <div class="c-lv-login">
    <el-tabs v-model="activeName">
    <el-tab-pane label="密码登录" name="first">
      <el-input v-model="input1" placeholder="手机号/用户名" class="elinput1"></el-input>
      <el-input v-model="input2" placeholder="请输入密码" ></el-input>
      <div class="btn1">登录</div>
      <div class="c-lv-inner">
        <div class="c-lci-wrap">
        <el-checkbox v-model="checked"></el-checkbox>
        <span>已阅读并同意</span>
        <a href="">《用户使用协议》</a>
        </div>
        <span class="c-forget-pwd">忘记密码</span>

      </div>
      <div class="c-account-else">
        <span>还没有账号？立即 </span>
        <span class="c-ae-reg"> 免费注册</span>
        
        </div>
    </el-tab-pane>
    <el-tab-pane label="短信登录" name="second">

      <div class="c-message-login">
        <div class="c-ml-m1">
          <el-select v-model="formInline.region" placeholder="+86" class="c-ml-input1">
          <el-option label="+86" value="86"></el-option>
          <el-option label="+87" value="87"></el-option>
          </el-select>
        </div>
      <div class="c-ml-m2">
        <el-input v-model="input3" placeholder="手机号/用户名"></el-input>
      </div>
      
      </div>
      
      <el-input v-model="input4" placeholder="请输入验证码" ></el-input>
      <div class="btn1">登录</div>
      <div class="c-lv-inner">
        <div class="c-lci-wrap">
        <el-checkbox v-model="checked"></el-checkbox>
        <span>已阅读并同意</span>
        <a href="">《用户使用协议》</a>
        </div>
        <span class="c-forget-pwd">忘记密码</span>

      </div>
      <div class="c-account-else">
        <span>还没有账号？立即 </span>
        <span class="c-ae-reg"> 免费注册</span>
        
      </div>
      <div class="c-msg-code">
        <span>获取短信验证码</span>
      </div>
      

    </el-tab-pane>
    

  </el-tabs>
  
 

  </div>
</div>

</template>

<script>
export default {
    data(){
      return{
        input1:"",
        input2:"",
        input3:"",
        input4:"",
        checked:"",
        activeName:"first",
        formInline: {
          user: '',
          region: ''
        }
      }
    }
}
</script>

<style scoped>
.c-login-logo{
  width: 20rem;
  height: 10rem;
  position: absolute;
  left: 19.88rem;
  top: 1.75rem;
}
.c-login-views-wrap>p:nth-child(1){
  width: 15rem;
  font-size: 1.88rem;
	color: #4964b4;
  padding-top: 9.69rem;
  margin: 0 auto 1.75rem auto;
}
.c-login-views-wrap>p:nth-child(2){
    width: 20rem;
    color: #4964b4;
    font-size: 1.25rem;
  margin: 0 auto 2.13rem auto;
}
.c-msg-code{
  font-size: 0.94rem;
  color: #3f83ff;
  position: absolute;
  bottom: 14.8rem;
  left: 16.5rem;
}
.c-message-login{
  width: 23.75rem;
  height: 2.76rem;
  margin-bottom: 1.31rem;
  display: flex;
  flex-direction: row;
}

.c-message-login .c-ml-m1 .el-input__inner{
  width: 4.8rem;
  height: 2.76rem;
  margin-left: 0.3rem;
  border-radius: 0.25rem 0 0 0.25rem;
  
}
.c-message-login .c-ml-m2 .el-input__inner{
  width: 19rem;
  height: 2.76rem;
  border-radius:0 0.25rem 0.25rem 0;
 
}
.c-ae-reg{
  color: #426efc;
}
.c-account-else{
  margin-top: 3.25rem;
  font-size: 0.88rem;

}
.c-lv-inner .el-checkbox{
  margin-right: 0.69rem;
}
.c-lv-inner{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.88rem;
}
.c-forget-pwd{
  color: #707070;
}

/* 头部 */
.c-login-views-wrap{
  width: 100%;
  height: 100%;
  background: url('../assets/images/denlubeijin.jpg');
}
.c-lv-login{
  width: 24.5rem;
  height: 30.15rem;
  padding: 0 1.5rem;
  margin: 0 auto;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0rem 0.31rem 0.81rem 0rem 
		rgba(30, 58, 150, 0.29);
	border-radius: 0.31rem;
}
/* 修改 element ui 登录框样式 */
.c-lv-login .el-tabs__active-bar{
  background-color: #434343;
}
.c-lv-login .el-tabs__item.is-active{
  color: #404040;
}
.c-lv-login .el-tabs__item:hover{
  color: #707070;
}
.c-lv-login .el-tabs__nav-wrap{
  margin: 2.56rem 0;
}
.c-lv-login .el-input__inner{
  width: 23.94rem;
	height: 2.75rem;
	background-color: #eeeeee;
	border-radius: 0.25rem;
	border: solid 0.06rem #dcdcdc;
}
.c-lv-login .elinput1{
  margin-bottom: 1.31rem;
}
.btn1{
  width: 23.94rem;
	height: 3.13rem;
  line-height: 3.13rem;
  text-align: center;
  color: #ffffff;
  margin: 2.63rem 0;
	background: url('../assets/images/denlu.png');
	border-radius: 0.3rem;
}
</style>
