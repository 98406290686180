<template>
    <div class="body">
        <Header></Header>
        <div id="totop">
            <div class="down">
                <div>
                    <div></div>
                    <div id="down-text-one">益帮手桶装水管理系统，让水站管理更高效</div>
                    <div>微信小程序商城+益帮手电脑端管理系统+益帮手移动端管理</div>
                    <div><span>益帮手 支持多家分店管理</span></div>
                    <div @click="toFunctionDownload()"><a>免费试用</a></div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="main-1">
            <div class="broadcast">
                <div class="img-block"><img src="../../../assets/images/home/02.png" alt=""></div>
                <div class="broadcast-title lazy"><b>目前桶装水行业电话订水的现状</b></div>
                <div class="broadcast-content lazy">桶装水行业目前存在诸多乱象，从电话订水，到市面上的软件都有许多问题 </div>
                <div class="broadcast-content">我们致力于打造一款属于您的好帮手，使用益帮手帮助您解决各种难题</div>
                <div>
                    <el-carousel :interval="3000" type="card">
                        <el-carousel-item v-for="item in carouselData" :key="item.id">
                            <div class="carousel">
                                <div class="carousel-l" >
                                    <img class="carousel-l-img" :src="item.img1" height="70" width="70"/>
                                </div>
                                <div class="carousel-r">
                                    <div class="carousel-r-t1">{{item.title}}</div>
                                    <div>
                                        <img class="carousel-r-img" src="../../../assets/images/home/43.png"/>
                                    </div>
                                    <div class="carousel-r-t2">{{item.content}}</div>
                                </div>
                                <img class="carousel-img" :src="item.img2" alt="">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>

            <div class="insufficient">
                <div class="img-block"><img src="../../../assets/images/home/03.png" alt=""></div>
                <div class="insufficient-title lazy"><b>目前桶装水配送软件的乱象</b> </div>
                <div class="insufficient-content lazy">目前桶装水软件大多都存在诸多问题，导致您使用上不能如意</div>
                <div class="insufficient-annotation">
                    <div class="i-a-left"><img src="../../../assets/images/home/09.png" alt=""/></div>
                    <div class="i-a-right">
                        <div>
                            <img src="../../../assets/images/home/38.png" alt="">
                            <span class="i-a-right-title lazy">不思进取，不升级软件</span>
                            <span class="i-a-right-key lazy">落后难用</span>
                            <div class="i-a-right-content lazy">一些软件公司不思进取，十年如一日的不升级软件。还停留在数十年前的架构与逻辑。与如今的系统环境格格不入，甚至出现了问题也迟迟不修复。</div>
                        </div>
                        <div>
                            <img src="../../../assets/images/home/35.png" alt="">
                            <span class="i-a-right-title lazy">没有移动支付，与时代脱节</span>
                            <span class="i-a-right-key lazy" style="background-color: #e95353;">跟不上时代</span>
                            <div class="i-a-right-content lazy">社会在发展，微信早已普及到人人都会用，客户需要微信购买水票怎么办？需要用微信付款怎么办？连这些功能都没有，客户怎么使用？</div>
                        </div>
                        <div>
                            <img src="../../../assets/images/home/36.png" alt="">
                            <span class="i-a-right-title lazy">售后服务奇差，与售前相差太大</span>
                            <span class="i-a-right-key lazy" style="background-color: #fc8e2b;">没有服务</span>
                            <div class="i-a-right-content lazy">一些软件公司售后态度奇差，售出后完全不把客户当回事，提出需求也置之不理。这样的软件，有保障吗？</div>
                        </div>
                        <div>
                            <img src="../../../assets/images/home/37.png" alt="">
                            <span class="i-a-right-title lazy">每年加收服务费</span>
                            <span class="i-a-right-key lazy" style="background-color: #ffc72f;">额外收费</span>
                            <div class="i-a-right-content lazy">一些软件公司，价格本来就贵，售后还要跟客户收取每年上千的服务费，说是终身使用，说到底还是要付很多钱。</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cases">
                <div class="img-block cases-img-block"><img src="../../../assets/images/home/10.png" alt=""></div>
                <div class="cases-title"><b>他们都在用益帮手·桶装水管理系统</b> </div>
                <div class="cases-example">
                    <div class="cases-e-items" v-for="(item, index) in caseData" :key="index" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
                        <div><img class="cases-e-img" :src="item.img1" alt=""></div>
                        <div class="cases-e-i-move" :style="{top: item.top +'rem'}">
                            <div class="cases-e-i-type">{{item.title}}</div>
                            <div class="cases-e-i-content">{{item.content}}</div>
                            <div class="cases-e-i-sign">
                                <img src="../../../assets/images/function-download/38.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="cases-wrap">-->
                    <!--<div class="ca-wp-item">-->
                        <!--<div class="cawp-item-t1">-->
                            <!--<img src="../../assets/images/home/44.png"/>-->
                        <!--</div>-->
                        <!--<div class="cawp-item-t2">一桶天下饮用水配送中心</div>-->
                        <!--<div class="cawp-item-t3">益帮手丰富的数据统计系统，每天经营数据一目了然</div>-->
                    <!--</div>-->
                <!--</div>-->
            </div>

            <div class="minip-info">
                <div class="img-block cases-img-block"><img src="../../../assets/images/home/14.png" alt=""></div>
                <div class="minip-info-title lazy"><b>益帮手能给您带来什么？</b></div>
                <div class="minip-info-content lazy" style="text-align: center;">益帮手将给您带来诸多服务，满足您的所有需求！</div>
                <div class="minip-info-div">
                    <div>
                        <div class="minip-info-function">
                            <div>
                                <div><img src="../../../assets/images/home/15.png" alt=""></div>
                                <div class="minip-info-title-s lazy">小程序商城</div>
                                <div class="minip-info-content lazy">这次益帮手为您带来了小程序商城，小程序能有效的引导用户自助下单，大大减少了水站的运营开支。</div>
                            </div>
                            <div>
                                <div><img src="../../../assets/images/home/16.png" alt=""></div>
                                <div class="minip-info-title-s lazy">全平台管理后台</div>
                                <div class="minip-info-content lazy">益帮手拥有手机与电脑同时管理的能力。在这个移动为王的年代，您通过益帮手手机版也可以轻松管理您的水站。</div>
                            </div>
                            <div>
                                <div><img src="../../../assets/images/home/17.png" alt=""></div>
                                <div class="minip-info-title-s lazy">丰富的营销工具</div>
                                <div class="minip-info-content lazy">如果您觉得营销难、推广难，那么益帮手为您准备了丰富的营销工具。通过营销工具与小程序的组合能给您带来丰富的客流量。</div>
                            </div>
                        </div>
                        <div class="minip-info-function">
                            <div>
                                <div><img src="../../../assets/images/home/18.png" alt=""></div>
                                <div class="minip-info-title-s lazy">数据统计</div>
                                <div class="minip-info-content lazy">财务数据、配送数据的统计一直以来都是水站管理最繁琐的问题，益帮手能轻松帮助您记录与统计财务信息、配送信息等数据。可以帮助您节省大量时间。</div>
                            </div>
                            <div>
                                <div><img src="../../../assets/images/home/19.png" alt=""></div>
                                <div class="minip-info-title-s lazy">多分店管理</div>
                                <div class="minip-info-content lazy">拥有多个分店的你，还在为没有一个能协同管理多分店的软件烦恼吗？益帮手来了，帮助您轻松管理分店，提升每个店铺的协调能力。</div>
                            </div>
                            <div>
                                <div><img src="../../../assets/images/home/20.png" alt=""></div>
                                <div class="minip-info-title-s lazy">交互逻辑清晰</div>
                                <div class="minip-info-content lazy">市面上的管理软件界面与逻辑完全停留在20年前，益帮手从人机工程学的角度出发，为您打造出了一款更加现代化，增加操作效率的管理系统。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contrast">
                <div class="img-block cases-img-block"><img src="../../../assets/images/home/10.png" alt=""></div>
                <div class="contrast-title lazy"><b>竞品与益帮手·桶装水管理系统产品对比</b></div>
                <div class="contrast-content lazy">其他软件有的功能我们都有，其他软件没有的功能我们也有，<br/>终身使用的软件，购买时一定要仔细斟酌的，<br/>让我们来一个功能大PK吧！</div>
                <div class="contrast-items">
                    <div class="contrast-i-title lazy">
                        <b>
                            <span class="contrast-i-l">同行桶装水软件</span>
                            <span class="contrast-i-m">VS</span>
                            <span class="contrast-i-r">同行桶装水软件</span>
                        </b>
                    </div>
                    <div class="contrast-i-row">
                        <div class="contrast-i-left">
                            <div class="contrast-i-l-title lazy">数据安全没有保障</div>
                            <div class="contrast-i-l-content lazy">市面上大多软件都为单机版，所有信息都存储在电脑上，<b>存在信息同步难</b>，一旦电脑出了问题，<b>数据安全也没有保障</b>。更换电脑时，<b>软件迁移也是相当困难</b>。</div>
                        </div>
                        <div><img src="../../../assets/images/home/22.png" alt=""></div>
                        <div class="contrast-i-right">
                            <div class="contrast-i-r-title lazy">专属云服务数据加密，数据安全有保障</div>
                            <div class="contrast-i-r-content lazy">益帮手采用了云存储模式作为数据存储，<b>商家数据安全得到有效保障</b>。即使换一台电脑，只需要登录账号<b>数据便能无缝接入</b>。即使数据误删除，也<b>可以进行恢复</b>。</div>
                        </div>
                    </div>
                    <div class="contrast-i-row">
                        <div class="contrast-i-left">
                            <div class="contrast-i-l-title lazy">没有小程序或高额收费</div>
                            <div class="contrast-i-l-content lazy">市面上大多数软件没有小程序商城，即使拥有小程序商城，<b>功能也非常单一</b>，<b>价格昂贵</b>，一个小程序商城就要<b>收取2000元的高额费用</b>。</div>
                        </div>
                        <div><img src="../../../assets/images/home/23.png" alt=""></div>
                        <div class="contrast-i-right">
                            <div class="contrast-i-r-title lazy">功能丰富的小程序，无额外收费</div>
                            <div class="contrast-i-r-content lazy">益帮手广大水站提供了<b>功能全面的小程序商城</b>。我们的小程序商城拥有<b>非常丰富的营销工具，界面美观</b>。小程序包含在980元套餐中，<b>无需二次收费</b>。</div>
                        </div>
                    </div>
                    <div class="contrast-i-row">
                        <div class="contrast-i-left">
                            <div class="contrast-i-l-title lazy">没有手机版管理后台</div>
                            <div class="contrast-i-l-content lazy">大多数软件都没有<b>手机管理的能力</b>，在这个移动化时代，没有手机管理店铺，你说方便不方便？</div>
                        </div>
                        <div><img src="../../../assets/images/home/24.png" alt=""></div>
                        <div class="contrast-i-right">
                            <div class="contrast-i-r-title lazy">超便捷手机端管理系统，无额外收费</div>
                            <div class="contrast-i-r-content lazy">益帮手给您准备了一款手机版管理后台，它拥有与电脑端管理后台一样的功能。方便您随时随地的<b>打开手机便能管理您的水站</b>。</div>
                        </div>
                    </div>
                    <div class="contrast-i-row">
                        <div class="contrast-i-left">
                            <div class="contrast-i-l-title lazy">没有营销工具，不能帮助您拓展生意</div>
                            <div class="contrast-i-l-content lazy">营销推广一直以来都是各大商家较为头疼的事，市面上的软件仅仅只是一款管理软件，<b>并不具备帮助您拓展生意的营销工具</b>。</div>
                        </div>
                        <div><img src="../../../assets/images/home/25.png" alt=""></div>
                        <div class="contrast-i-right">
                            <div class="contrast-i-r-title lazy">数十款营销工具供您任意选择</div>
                            <div class="contrast-i-r-content lazy">益帮手准备了<b>十几个营销工具方案</b>，我们相信，一款好的管理系统，不只是具备哪些基础的管理功能，更需要做到能<b>成为您生意上的好帮手</b>才行。</div>
                        </div>
                    </div>
                    <div class="contrast-i-row">
                        <div class="contrast-i-left">
                            <div class="contrast-i-l-title lazy">纸质水票存在诸多问题</div>
                            <div class="contrast-i-l-content lazy">传统软件一般只提供水票记录，这种功能需要<b>手动去填写</b>，<b>及其不方便</b>，同时<b>纸质票印刷成本高、难保管、统计难等诸多问题</b>。</div>
                        </div>
                        <div><img src="../../../assets/images/home/26.png" alt=""></div>
                        <div class="contrast-i-right">
                            <div class="contrast-i-r-title lazy">摆脱传统水票采用电子水票</div>
                            <div class="contrast-i-r-content lazy">益帮手带来了全新的<b>电子水票功能</b>，商家只需要对用户发行电子水票，用户购买后存储于他们的<b>小程序钱包</b>中，下单<b>自动结算</b>。商家管理方便，点一点便能<b>查看用户还剩多少水票</b>。彻底摆脱了<b>水票易丢失、管理难、印刷成本高</b>等问题。</div>
                        </div>
                    </div>
                    <div class="contrast-i-row">
                        <div class="contrast-i-left">
                            <div class="contrast-i-l-title lazy">售前售后态度差别极大</div>
                            <div class="contrast-i-l-content lazy">购买后遇到问题，解决需要<b>按次收取高额服务费</b>，售后稳定性得不到保障，<b>使用成本高</b>。</div>
                        </div>
                        <div><img src="../../../assets/images/home/27.png" alt=""></div>
                        <div class="contrast-i-right">
                            <div class="contrast-i-r-title lazy">售后一对一指导完全免费</div>
                            <div class="contrast-i-r-content lazy">提供优质免费的售后服务，售后安排技术进行<b>一对一指导</b>，购买后软件问题解决指导<b>完全免费</b>。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactUs></ContactUs>
        <Bottom></Bottom>
    </div>
</template>

<script>
    import Header from '@/components/Pc/Header';
    import Bottom from '@/components/Pc/Bottom';
    import ContactUs from '@/components/Pc/ContactUs';
    export default {
        name: "index",
        components: {
            Header,
            Bottom,
            ContactUs,
        },
        data() {
          return {
              carouselData:[
                  {
                      id: 1,
                      img1: require('../../../assets/images/home/1xiao.png'),
                      img2: require('../../../assets/images/home/1.png'),
                      title: "无论客户定多少次水，总是会打电话问你水送到哪里！",
                      content: "答：益帮手·桶装水管理系统提供实时定位功能，方便用户与管理员实时查看送水员位置。配送员提供路线规划，大大提升送水效率，减少用户等待时间。"
                  },
                  {
                      id: 2,
                      img1: require('../../../assets/images/home/2xiao.png'),
                      img2: require('../../../assets/images/home/2.png'),
                      title: "对外电话号码多，管理难，对客户记忆难，高峰时段电话容易占线，客户的预定成功率大受影响！",
                      content: "答：益帮手·桶装水管理系统提供两种下单方式，一.使用微信小程序自助下单。二.来电弹屏代客下单，通过系统自动记录客户资料，来电直接获取信息与订水记录，减少电话沟通时间。通过两种模式分流，有效的减少了电话的占线频率。"
                  },
                  {
                      id: 3,
                      img1: require('../../../assets/images/home/3xiao.png'),
                      img2: require('../../../assets/images/home/3.png'),
                      title: "纸上登记财务，易混乱、易出错，而且手工统计还很难统计一天卖了多少水！",
                      content: "答：您只需要打开电脑或手机的益帮手管理系统，随时随地可以查询到每笔订单数据，从任何时间段的销售数据，到每个配送员哪天送了什么东西都可以查询的到。"
                  },
                  {
                      id: 4,
                      img1: require('../../../assets/images/home/4xiao.png'),
                      img2: require('../../../assets/images/home/4.png'),
                      title: "接线员如果写错地址，导致配送员不能顺利收款，还需要打电话与客户反复确认，占用了宝贵的时间！",
                      content: "答：客户只要有通过小程序在益帮手·桶装水管理系统下过单，或商家主动为客户录入信息。下次下单时，系统便能自动获取用户的地址信息，避免了填错的情况。"
                  },
                  {
                      id: 5,
                      img1: require('../../../assets/images/home/5xiao.png'),
                      img2: require('../../../assets/images/home/5.png'),
                      title: "服务效能低，大量错失订单。",
                      content: "答：益帮手·桶装水管理系统带来的不仅仅是管理系统，我们打破了常规低效的下单模式，带来了提升数倍的服务效率与效益。"
                  },
                  {
                      id: 6,
                      img1: require('../../../assets/images/home/6xiao.png'),
                      img2: require('../../../assets/images/home/6.png'),
                      title: "无法快速知道送水员的配送量，难于计算送水员，每天、每月、及任意时间段的配送量。",
                      content: "答：传统方式下计算送水提成费时费力。如今益帮手提供了实时的配送员配送统计，方便您查询配送员任意时间段的配送量；系统自动计算员工提成工资，免去您查找送水单一笔一笔去计算员工提成的繁琐"
                  },
                  {
                      id: 7,
                      img1: require('../../../assets/images/home/7xiao.png'),
                      img2: require('../../../assets/images/home/7.png'),
                      title: "还没跟上互联网时代？如今掌上购物人人都会，手机下单是刚需，但桶装水行业还没有一款像样的商城？",
                      content: "答：别担心，这么久才来见您，益帮手花费了大把时间为广大水站带来了优秀的小程序商城，商城接入管理后台数据。  简单高效，使用小程序给商家省去大量的成本。"
                  },
                  {
                      id: 8,
                      img1: require('../../../assets/images/home/8xiao.png'),
                      img2: require('../../../assets/images/home/8.png'),
                      title: "目前桶装水行业竞争激烈，客户难找，推广渠道还比较单一。效率低下，成本还高。",
                      content: "答：益帮手·桶装水管理系统为您准备了数款营销工具有效的帮助您节省成本，提高效率。有了营销工具，打造爆炸式、裂变式营销，留存客户等，都已经不再是难事。"
                  },

              ],
              caseData:[
                  {
                      id: 1,
                      img1: require('../../../assets/images/home/40.png'),
                      img2: require('../../../assets/images/home/11.png'),
                      title: '一桶天下饮用水配送中心',
                      content: '益帮手丰富的数据统计系统，每天经营数据一目了然',
                      top: 0,
                  },
                  {
                      id: 2,
                      img1: require('../../../assets/images/home/41.png'),
                      img2: require('../../../assets/images/home/12.png'),
                      title: '集汇祥送水到府',
                      content: '多样化的营销工具助力我们开展丰富的营销活动',
                      top: 0,
                  },
                  {
                      id: 3,
                      img1: require('../../../assets/images/home/44.png'),
                      img2: require('../../../assets/images/home/13.png'),
                      title: '水博士生活配送',
                      content: '数据准确性得到保障，数十家门店得到更好的管理',
                      top: 0,
                  },
                  {
                      id: 4,
                      img1: require('../../../assets/images/home/42.png'),
                      img2: require('../../../assets/images/home/13.png'),
                      title: '百姓饮水配送中心',
                      content: '益帮手强大的路线规划系统，使我们配送效率提高60%',
                      top: 0,
                  },
              ],
          }
        },
        methods: {
            /*
           * 功能与下载
           * */
            toFunctionDownload() {
                this.$router.push({
                    path: '/function-download'
                });
            },

            /**
             * 鼠标移入
             */
            mouseenter(val){
                this.caseData[val].top = -18;
            },
            mouseleave(val){
                this.caseData[val].top = 0;
            }
        },

        created:function (){
        },
        mounted() {

        },
    }
</script>

<style scoped>
    /* 下载栏  */
    .down{
        width: 100%;
        height: 39.063rem;
        background: #fff url("../../../assets/images/home/01.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
    }
    #down-text-one{
        letter-spacing: 0.45rem;
    }
    .down>div:nth-of-type(1){
        height: Inherit;
        display: flex;
        flex-direction: column;
        /*justify-content: space-around;*/
        align-items: center;
    }
    .down>div:nth-of-type(1)>div:nth-of-type(1){
        font-size: 5.861rem;
        color: #ffffff;
        margin-top: 4.938rem;
    }
    .down>div:nth-of-type(1)>div:nth-of-type(2){
        font-size: 2.1rem;
        color: #ffffff;
        margin-top: 3.063rem;

    }
    .down>div:nth-of-type(1)>div:nth-of-type(3){
        font-size: 1.5rem;
        color: #ffffff;
        margin-top: 2.563rem;

    }
    .down>div:nth-of-type(1)>div:nth-of-type(4){
        width: 21.125rem;
        height: 3.563rem;
        background-color: #ffffff;
        border-radius: 1.75rem;
        text-align: center;
        margin-top: 2.875rem;
    }
    .down>div:nth-of-type(1)>div:nth-of-type(4)>span{
        font-size: 1.25rem;
        line-height: 3.563rem;
        letter-spacing: 0.025rem;
        color: #3835ae;
    }
    .down>div:nth-of-type(1)>div:nth-of-type(5){
        width: 10.625rem;
        height: 3.813rem;
        background-color: #e24b2d;
        box-shadow: 0.55rem 0.681rem 1.575rem 0.238rem rgba(177, 56, 32, 0.2);
        border-radius: 0.625rem;
        text-align: center;
        margin-top: 3.785rem;
        cursor: pointer;

    }
    .down>div:nth-of-type(1)>div:nth-of-type(5)>a{
        font-size: 1.5rem;
        line-height: 3.813rem;
        letter-spacing: 0.031rem;
        color: #ffffff;
    }

    .main-1{
        width: 100%;
        /*height: 193.75rem;*/
        background: #fff url('../../../assets/images/home/04.png') 0 10rem;
    }
    .broadcast{
        text-align: center;
    }
    .broadcast-title,.insufficient-title,.cases-title,.minip-info-title,.contrast-title{
        /*width: 27.25rem;*/
        /*height: 1.875rem;*/
        font-size: 1.875rem;
        line-height: 2.75rem;
        letter-spacing: 0.094rem;
        color: #404040;
        margin-bottom: 1rem;
    }
    .broadcast-content,.insufficient-content,.i-a-right-content,.minip-info-content,.contrast-content{
        /*width: 32.813rem;*/
        /*height: 2.875rem;*/
        font-size: 0.938rem;
        line-height: 1.875rem;
        letter-spacing: 0.05rem;
        color: #404040;
    }

    .el-carousel{
        width: 56% !important;
        height: 23.06rem !important;
        overflow: hidden;
        margin: 0 auto !important;
    }
    .el-carousel__item{
        /*width: 50% !important;*/
        height: 17.56rem !important;
        box-shadow: 0rem 0.13rem 0.45rem 0.05rem rgba(53, 85, 120, 0.21) !important;
        border-radius: 0.31rem !important;
    }
    .el-carousel__item:nth-of-type(1){
        background: linear-gradient(top left, #7874E8, #9C80DB) !important;
    }
    .el-carousel__item:nth-of-type(2){
        background: linear-gradient(top left, #1ADBFF, #45B4FE) !important;
    }
    .el-carousel__item:nth-of-type(3){
        background: linear-gradient(top left, #4C6BFF, #4ca5ff) !important;
    }
    .el-carousel__item:nth-of-type(4){
        background: linear-gradient(top left, #529df1, #4b65e7) !important;
    }
    .el-carousel__item:nth-of-type(5){
        background: linear-gradient(top left, #7874e8, #9c80db) !important;
    }
    .el-carousel__item:nth-of-type(6){
        background: linear-gradient(top left, #7874e8, #5193f7) !important;
    }
    .el-carousel__item:nth-of-type(7){
        background: linear-gradient(top left, #1adbff, #45b4fe) !important;
    }
    .el-carousel__item:nth-of-type(8){
        background: linear-gradient(top left, #4ab7f6, #9c80db) !important;
    }
    .broadcast>>>.el-carousel__arrow{
        top: 48% !important;
    }
    .carousel{
        display: flex;
    }
    .carousel-img{
        width: 7.31rem;
        height: 6.44rem;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .carousel-l{
        padding: 2.38rem 0 0 2.06rem;
    }
    .carousel-l-img{
        width: 4.38rem;
        height: 4.38rem;
    }
    .carousel-r>div{
        width: 24rem;
        text-align: left;
    }
    .carousel-r-t1{
        font-size: 1rem;
        color: #ffffff;
        margin-top: 3.31rem;
    }
    .carousel-r-img{
        margin-top: 2.19rem;
    }
    .carousel-r-t2{
        font-size: 0.88rem;
        color: #fafafa;
        margin-top: 1.31rem;
    }

    .img-block{
        text-align: center;
        margin-top: 1rem;
    }
    .insufficient{
        text-align: center;
    }
    .insufficient-annotation{
        width: 66rem;
        /*height: 21.813rem;*/
        display: flex;
        justify-content: space-between;
        margin: 3rem auto 0;
    }
    .i-a-left{
        width: 31.813rem;
        height: initial;
        display: flex;
        align-items: center;
    }
    .i-a-left>img{
        width: 100%;
        height: auto;
    }
    .i-a-right{
        width: 31.063rem;
        text-align: left;
    }
    .i-a-right-title,.minip-info-title-s{
        width: 11.813rem;
        height: 1.125rem;
        font-size: 1.125rem;
        line-height: 1.125rem;
        letter-spacing: 0.056rem;
        font-weight: bold;
        color: #404040;
    }
    .i-a-right-key{
        display: inline-block;
        padding: 0 .3rem ;
        text-align: center;
        background-color: #f05f43;
        border-radius: 0.188rem;
        font-size: 0.938rem;
        letter-spacing: 0.05rem;
        color: #ffffff;
        margin-left: 1rem;
    }

    .cases{
        width: 100%;
        height: 42.813rem;
        background-color: #f1f7fc;
        margin-top: 10rem;
        text-align: center;
        overflow: hidden;
    }
    .cases-img-block{
        margin-top: 2rem;
    }
    .cases-example{
        display: flex;
        margin: 0 auto;
        justify-content: center;
    }
    .cases-e-items{
        margin: 1rem .5rem;
        overflow: hidden;
        width: 16.63rem;
        height: 19.56rem;
        background-color: #ffffff;
        box-shadow: 0rem 0rem 0.95rem 0.05rem
        rgba(22, 20, 96, 0.1);
        border-radius: 0.63rem;
        text-align: center;
    }
    .cases-e-img{
        width: 6.88rem;
        height: 6.88rem;
    }
    .cases-e-i-move{
        width: 16.63rem;
        min-height: 19.56rem;
        background: #fff;
        overflow: hidden;
        position: relative;
        top: 0;
        transition: top 0.5s;
    }
    .cases-e-i-type{
        width: 13.94rem;
        font-size: 1.13rem;
        letter-spacing: 0.06rem;
        color: #404040;
        margin: 1.94rem auto 0;
        font-weight: bold;
    }
    .cases-e-i-content{
        text-align: center;
        width: 13.94rem;
        margin: 2rem auto 0;
        font-size: 0.94rem;
        line-height: 1.88rem;
        letter-spacing: 0.05rem;
        color: #404040;
    }
    .cases-e-i-sign>img{
        width: 13.5rem;
        height: 13.56rem;
        margin-top: 5rem;
    }

    .minip-info{
        width: 100%;
        text-align: center;
    }
    .minip-info-div{
        height: auto;
        width: 66rem;
        margin: 0 auto;
    }
    .minip-info-div>div:nth-of-type(1){
        margin-top: 6%;
    }
    .minip-info-div>div:nth-of-type(2){
        width: 100%;
        height: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .minip-info-function{
        display: flex;
        justify-content: space-between;
    }
    .minip-info-function>div{
        height: 18.563rem;
        width: 18.626rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .minip-info-title-s{
        margin: 1.5rem 0;
    }
    .minip-info-content{
        text-align: left;
    }

    .contrast{
        text-align: center;
        width: 100%;
        height: auto;
        overflow: hidden;
    }
    .contrast-content{
        margin: 1rem 0;
    }
    .contrast-items{
        min-width: 66rem;
        height: auto;
        margin: 0 auto;
    }
    .contrast-i-title{
        margin: 2rem 0;
    }
    .contrast-i-l,.contrast-i-m,.contrast-i-r{
        font-size: 1.563rem;
        color: #71a7ec;
    }
    .contrast-i-m{
        display: inline-block;
        width: 15rem;
        color: #1370e7;
    }
    .contrast-i-r{
        color: #1370e7;
    }
    .contrast-i-row{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 0;
    }
    .contrast-i-row>div:nth-of-type(2){
        width: 15rem;
    }
    .contrast-i-left,.contrast-i-right{
        width: 27.188rem;
    }
    .contrast-i-l-title,.contrast-i-r-title{
        font-size: 1.125rem;
        line-height: 1.625rem;
        color: #404040;
        font-weight: bold;
        text-align: right;
    }
    .contrast-i-l-content,.contrast-i-r-content{
        font-size: 0.938rem;
        line-height: 1.625rem;
        color: #404040;
        text-align: right;

    }
    .contrast-i-r-title{
        text-align: left;
    }
    .contrast-i-r-content{
        text-align: left;
    }

</style>
