<template>
    <div>
        <Header></Header>
        <div class="about-us">
            <div class="about-us-l">
                <div class="about-us-m">
                    <div>关于我们</div>
                    <div class="about-us-s">
                        <div><a href="#about">关于我们</a></div>
                        <div><a href="#point2">项目合作与推广</a></div>
                        <div><a href="#point3">联系我们</a></div>
                        <div @click="dialogVisible=true"><a href="#point4">咨询</a></div>
                    </div>
                    <div class="about-us-img">
                        <a href="#about"><img v-lazy="require('../../../assets/images/function-download/down.png')" alt="向下"></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="description" id="about">
            <div class="des-o">
                <div class="des-o-title">关于我们</div>
                <div class="des-o-subtitle">为理想而生，为您的颠覆而来</div>
                <div class="des-o-content">厦门宅职社网络科技有限公司,是一家从事商业生态智能信息化的互联网技术公司，积累了雄厚的多元化电子商务平台的研发、运营的技术经验。目前旗下产品为“益帮手”，旨在给水站商户提供一整套的O2O智慧水站管理服务。彻底颠覆传统水站运营模式，开启水站管理信息、智能化时代！我们为理想而生、为您的颠覆而来，专业的团队为您提供网站程序、APP、微信公众平台、小程序开发服务，把您想法、需求变为现实。亲爱的伙伴们，闻道有先后，术业有专攻，专业的事还是专门交给我们专业的人来做吧！也许我们可以做一些不一样的尝试！</div>
            </div>
            <div class="des-t">
                <div class="des-t-top">
                    <div class="des-t-top-l">
                        <!-- 10 -->
                        <div class="des-t-top-l-t">10</div>
                        <div class="des-t-top-l-b lazy">年互联网行业经验</div>
                    </div>
                    <div class="des-t-top-l">
                        <!-- 5 -->
                        <div class="des-t-top-l-t">5</div>
                        <div class="des-t-top-l-b lazy"  >年项目工程师经验</div>
                    </div>
                    <div class="des-t-top-l">
                        <!-- 200 -->
                        <div class="des-t-top-l-t">200</div>
                        <div class="des-t-top-l-b lazy"  >位合作伙伴</div>
                    </div>
                </div>
                <div class="des-t-bottom">
                    <div class="des-t-bottom-title lazy"  >探索未来</div>
                    <div class="des-t-bottom-content lazy"  >宅职社致力于打造基于最新互联网理念，为广大企业、商家提供开发、管理、培训、营销等一体化的解决方案。以最新的互联网理念为中心，结合各行业的经验，将新零售与传统行业结合，开发有利于社会发展和进步的优秀产品与方案，在线上线下同城服务（O2O）、社会化客户关系管理(SCRM)、移动电商(VSHOP)、云服务、智能化软件服务、计算机软件定制开发、手机APP定制开发、技术咨询等系列产品及应用，构建一个生态的互联网平台</div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="main-l">
                <div class="main-l-l">
                    <img class="main-l-l-img" v-lazy="require('../../../assets/images/function-download/intelligence.jpg')" alt="智能">
                </div>
                <div class="main-l-m">
                    <div class="main-l-m-sub-o">
                        <img class="main-l-m-img" v-lazy="require('../../../assets/images/function-download/major.png')" alt="专业">
                    </div>
                    <div class="main-l-m-sub-t">
                        <img class="main-l-m-img" v-lazy="require('../../../assets/images/function-download/high-efficiency.jpg')" alt="高效">
                    </div>
                </div>
                <div class="main-l-r">
                    <img class="main-l-r-img" v-lazy="require('../../../assets/images/function-download/future.jpg')" alt="未来">
                </div>
            </div>
            <div class="main-text lazy"  >智能·专业·高效·未来</div>
        </div>
        <div class="main2" id="point2">
            <div class="main2-t">
                <div class="main2-t-l">
                    <div class="main2-tl-title lazy"  >项目合作与推广</div>
                    <div class="main2-tl-content lazy" style="opacity: 1">"新零售概念的逐步落地以及衍生于它的S2b商业模式的兴起在将人们关注的焦点带入到全新时代的同时，同样在颠覆着人们在传统时代对于线上和线下的认知。按照传统逻辑，线上与线下是水火不相容的存在。互联网时代的到来、人们生活方式的改变更是将传统商超的发展挤兑到了一个相对较为狭小的领域，由此宅职社邀您携手共掘万亿市场。"</div>
                    <div class="main2-tl-btn">
                        <a class="main2-t-btn-a" @click="dialogVisible=true">
                            <div class="main2-tl-subbtn lazy">了解详情</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- 联系我们 -->
        <ContactUs id="point3"></ContactUs>
        <!--<div class="form">-->
            <!--<div class="form-two">-->
                <!--<div class="common-fonts form-title">咨询</div>-->
                <!--<div class="form-form">-->
                  <!--<div class="form-form-texts">-->
                    <!--<input class="common-fonts form-form-text" type="text" placeholder="您的姓名：">-->
                    <!--<input class="common-fonts form-form-text" type="text" placeholder="您的邮箱：">-->
                    <!--<input class="common-fonts form-form-text" type="text" placeholder="您的联系方式：">-->
                  <!--</div>-->
                  <!--<div class="form-form-textareas">-->
                    <!--<textarea class="common-fonts form-form-textarea" placeholder="合作需求：详细产品描述、功能规划、数量、软件平台等" name="" id="" cols="30" rows="10"></textarea>-->
                  <!--</div>-->
                <!--</div>-->
                <!--<div  class="form-form-submits">-->
                  <!--<button class="common-fonts form-form-submit">提交</button>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <Bottom></Bottom>

        <!-- 弹窗 -->
        <div class="show-qrcode">
            <el-dialog
                    :visible.sync="dialogVisible"
                    width="27.81rem" >
                <div class="qrcode-t">
                    <div class="qrcode-t-w1">
                        <img v-lazy="require('../../../assets/images/function-download/36.png')" height="34" width="115"/>
                        <img @click="dialogVisible=false" v-lazy="require('../../../assets/images/function-download/37.png')" height="20" width="20"/>
                    </div>
                    <div class="qrcode-t-w2">客户经理：小易</div>
                    <div class="qrcode-t-w3">
                        <img v-lazy="require('../../../assets/images/function-download/38.png')" height="305" width="301"/>
                    </div>
                    <div class="qrcode-t-w4">
                        <img v-lazy="require('../../../assets/images/function-download/39.png')" height="29" width="30"/>
                        <span>微信扫码联系客服经理</span>
                    </div>
                </div>
                <div class="qrcode-b">
                    联系电话：18959935543
                    <br/>
                    <br/>
                    添加客户经理微信号：18959935543 了解疑问
                </div>
            </el-dialog>
        </div>
        <CustomerService></CustomerService>
    </div>
</template>

<script>
    import Header from '@/components/Pc/Header';
    import Bottom from '@/components/Pc/Bottom';
    import ContactUs from '@/components/Pc/ContactUs';
    import CustomerService from '@/components/Pc/CustomerService';

    export default {
        name: "ServiceSupport",
        components:{
            Header,
            Bottom,
            ContactUs,
            CustomerService
        },
        data(){
            return{
                dialogVisible: false
            }
        }
    }
</script>

<style scoped>
    /* 弹窗 */

    .show-qrcode>>>.el-dialog{
        border-radius: 1.5rem;
    }
    .show-qrcode>>>.el-dialog__body{
        padding: 0;
    }
    .show-qrcode>>>.el-dialog__header{
        display: none;
    }

    .qrcode-t{
        padding: 30px 20px 0 20px;
        height: 29rem;
    }
    .qrcode-t-w1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .qrcode-t-w2{
        width: 17.94rem;
        height: 2.13rem;
        background-color: #f7f7f7;
        border-radius: 1.06rem;
        font-size: 1rem;
        line-height: 2.13rem;
        color: #404040;
        margin: 1.91rem auto 0;
        text-align: center;
    }
    .qrcode-t-w3{
        text-align: center;
        margin-top: 1rem;
    }
    .qrcode-t-w4{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.38rem;
        letter-spacing: 0.07rem;
        color: #404040;
    }
    .qrcode-t-w4>span{
        margin-left: 1rem;
    }
    .qrcode-b{
        height: 5.63rem;
        font-size: 0.94rem;
        background-color: #f7f7f7;
        color: #404040;
        border-radius: 0 0 1.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* 弹窗 */
    /* 关于我们 */
    .about-us{
        height: 39.875rem;
        width: 100%;
        justify-content: center;
        align-items: center;
        background:#dfdfdf url("../../../assets/images/function-download/master_graph.jpg") no-repeat center center;
    }
    .about-us-l{
        height: 30.125rem;
        width: 56.6666%;
        padding-top: 10.5%;
        margin-left: 21%;
    }
    .about-us-m{
        height: 10.437rem;
        display: flex;
        flex-direction: column;
        margin-top:7%;
        justify-content: space-between;

    }
    .about-us-m>div:nth-of-type(1){
        width: 9rem;
        height: 2.188rem;
        font-size: 2.25rem;
        line-height: 0.758rem;
        /*letter-spacing: 0.044rem;*/
        color: #303030;
    }
    .about-us-s{
        display: flex;
        width: 33.438rem;
        justify-content: space-between;

    }
    .about-us-s div{
        padding-bottom:2px;
        border-bottom:2px solid transparent;

    }

    .about-us-s div:hover{
        border-bottom:2px solid #0e8ef7;
    }
    .about-us-s div a{
        width: 5.938rem;
        height: 1.438rem;
        font-size: 1.5rem;
        line-height: 0.758rem;
        letter-spacing: 0.031rem;
        color: #303030;
    }
    .about-us-img{
        width: 2.375rem;
        height: 2.375rem;
    }
    .about-us-img img{
        margin-top:155%;
    }
    /* 文字描述-关于我们 */
    .description{
        width: 100%;
        background: white;
        padding-bottom: 4.25rem;
    }
    .des-o{
        width: 56.6666%;
        margin: 0 auto;
        text-align: center;
        border-bottom:1px solid #d2d2d2;
        padding-bottom: 4.375rem;
    }
    .des-o-title{
        font-size: 1.875rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0.375rem;
        color: #303030;
        padding-top:4.313rem;
    }
    .des-o-subtitle{
        font-size: 1.375rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0.275rem;
        color: #303030;
        padding-top:2rem;
    }
    .des-o-content{
        font-size: 0.938rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.604rem;
        letter-spacing: 0.019rem;
        color: #303030;
        padding-top:2.125rem;
    }
    .des-t{
        width: 56.6666%;
        margin:0 auto;
    }
    .des-t-top{
        display: flex;
        justify-content: space-between;
        width: 77%;
        margin: 8.313% auto 8.313%;
    }
    .des-t-top-l{
        text-align: center;
    }

    .des-t-top-l-t{
        font-size: 3.125rem;
        letter-spacing: 0.156rem;
        color: #0e8ef6;
    }
    .des-t-top-l-b{
        font-size: 1.563rem;
        letter-spacing: 0.081rem;
        color: #303030;
    }
    .des-t-bottom{
        text-align: center;
    }
    .des-t-bottom-title{
        font-size: 1.375rem;
        letter-spacing: 0.275rem;
        color: #303030;
        padding-top: 2.563rem;
    }
    .des-t-bottom-content{
        font-size: 0.938rem;
        line-height: 2.604rem;
        letter-spacing: 0.019rem;
        color: #303030;
        padding-top: 2.563rem;

    }
    /* 图文介绍 */
    .main{
        height: 28.312rem;
        width: 100%;
        background-color: #434343;
        display: flex;
        flex-direction: column;
        padding-top: 4.438rem;
    }
    .main-l{
        width: 56.6666%;
        margin:0 auto;
        display: flex;
    }
    .main-l-l {
        width: 27.125rem;
        height: 24rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-l-l-img{
        /*width: 27.125rem;*/
        /*height: 24rem;*/
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    /*.main-l-l-img:hover{
      height: 105%;
      width: 105%;
    }*/
    .main-l-m-sub-o{
        width: 18.063rem;
        height: 12.813rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-l-m-sub-t{
        width: 18rem;
        height: 11.25rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-l-m-img{
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    /*.main-l-m-img:hover{
      height: 105%;
      width: 105%;
    }*/
    .main-l-r{
        width: 24.125rem;
        height: 24.063rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-l-r-img{
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    /*.main-l-r-img:hover{
      height: 105%;
      width: 105%;
    }*/
    .main-text{
        height: 4.438rem;
        font-size: 1.875rem;
        letter-spacing: 0.038rem;
        color: #ffffff;
        margin: 0 auto;
        line-height: 4.438rem;
    }
    .main2{
        width: 100%;
        height: 32.75rem;
        background: #fff url("../../../assets/images/function-download/rectangle.jpg") no-repeat center center;
    }
    .main2-t{
        width: 50%;
        height: 32.75rem;
        display: flex;
        justify-content: flex-end;
    }
    .main2-t-l{
        text-align: center;
        margin-right: 5%;
    }
    .main2-tl-title{
        width: 29.938rem;
        height: 1.813rem;
        font-size: 1.875rem;
        letter-spacing: 0.375rem;
        color: #ffffff;
        margin-top: 5.5rem;
    }
    .main2-tl-content{
        width: 29.938rem;
        height: 11.375rem;
        font-size: 0.938rem;
        line-height: 2.604rem;
        color: #ffffff;
        margin-top: 3.125rem;
    }
    .main2-tl-btn{
        display: flex;
        justify-content: center;
    }
    .main2-tl-subbtn{
        width: 9.375rem;
        height: 3.063rem;
        border-radius: 0.938rem;
        border: solid 0.125rem #ffffff;
        margin-top: 3.983rem;
    }
    .main2-t-btn-a{
        font-size: 1.25rem;
        line-height: 3.063rem;
        letter-spacing: 0.025rem;
        color: #ffffff;
        cursor: pointer;
    }
    /* 表单 */
    .c-common-problem .c-cp-piano .el-collapse .el-collapse-item,.cc{
        background-color: #f1f7fc;
    }
    .c-common-problem .c-cp-piano{
        width: 51.75rem;
        height: 27.56rem;
        color: #404040;
        margin: 0 auto;
        background-color: #f1f7fc;
    }
    .c-common-problem{
        width: 100%;
        margin-top: 8rem;
        height: 33rem;
        background-color: #f1f7fc;
    }
    .c-common-problem>div:nth-child(1){
        width: 8.38rem;
        height: 1.88rem;
        margin:0 auto;
        padding:4rem 0;
        font-size: 1.88rem;
        color: #404040;

    }
    .c-ad-show-wrap>img{
        margin-right:1.3rem;
    }
    .c-ss-msg{
        width: 25.31rem;
        height: 1.88rem;
        font-size: 1.88rem;
        color: #404040;
        margin: 3rem auto;
    }

    .form {
        background: #fff;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        bottom: 7rem;
    }
    .form-title{
        font-size: 1.875rem;
        color: #303030;
        text-align: center;
        margin: 5% 0;
    }
    .form-two{
        /*width: 56%;*/
        width:64rem;
    }
    .form-form{
        display: flex;
        width: 100%;
    }
    .form-form-texts{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width:35%;
    }
    .form-form-text{
        width: 100%;
        height: 3.438rem;
        border-radius: 0.625rem;
        border: solid 0.125rem #aaaaaa;
        font-size: 1.125rem;
        text-indent: 1rem;
        outline:none;
    }
    .form-form-textareas{
        width:65%;
    }
    .form-form-textarea{
        width: 98%;
        height: 14rem;
        border-radius: 0.625rem;
        border: solid 0.125rem #aaaaaa;
        margin:0 0 0 2%;
        outline:none;
        font-size: 1.125rem;
        padding-top:2%;
        text-indent: 1rem;
        resize:none;
    }
    .form-form-submits{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form-form-submit{
        width: 9.375rem;
        height: 3.063rem;
        background-color: #0e8ef6;
        border-radius: 0.938rem;
        border:0;
        font-size: 1.25rem;
        color: #ffffff;
        margin:4% 0;
        outline:none;
    }
</style>
