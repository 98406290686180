<template>
    <div>
        <div class="c-connect-we">
            <div>联系我们</div>
            <div class="c-cw-inner">
                <div class="c-cwi-wrap">
                    <div>
                        <img v-lazy="require('../../../assets/images/location.png')" alt="">
                        <p>厦门市思明区宜兰路99号海峡明珠广场4层410-1B单元</p>
                    </div>
                    <div>
                        <img v-lazy="require('../../../assets/images/computed.png')" alt="" align="center">
                        <p>厦门宅职社网络科技有限公司</p>
                    </div>
                </div>
                <div class="c-cwi-wrap">
                    <div>
                        <img v-lazy="require('../../../assets/images/phone.png')" alt="">
                        <div class="c-cwii">
                            <p>18959935543</p>
                            <p>‭(0592) 5793363‬</p>
                        </div>
                    </div>
                    <div>
                        <img v-lazy="require('../../../assets/images/qq.png')" alt="" align="center">
                        <p>5353826</p>
                    </div>
                </div>
                <!-- 在线咨询 -->
                <!--<div class="c-online-zx" style="margin-bottom:1rem;margin-top:3.5rem;">在线咨询</div>-->
                <!--<div class="c-online-zx">详细地图</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactUs"
    }
</script>

<style scoped>
    /* 联系我们 */
    .c-connect-we{
        width: 100%;
        /*height: 38rem;*/
        height: 26rem;
        background-color: #fff;
    }
    .c-connect-we>div:nth-child(1){
        width: 7.63rem;
        height: 1.81rem;
        padding-top: 4rem;
        font-size: 1.88rem;
        color: #303030;
        margin: 0 auto;
    }
    .c-cw-inner{
        width: 48rem;
        height: 25rem;
        margin: 0 auto;
        margin-top: 4rem;
    }
    .c-cwi-wrap>div>p{
        width: 14rem;
        height: 2.8rem;
        display: flex;
        align-items: center;
        margin-left: .88rem;
    }
    .c-cwii{
        margin-left: .88rem;
    }
    .c-cwi-wrap{
        width: 45rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        font-size: 0.94rem;
    }

    .c-cwi-wrap>div:nth-child(1),.c-cwi-wrap>div:nth-child(2){
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .c-cwi-wrap>div .cwii{
        margin-left: 1rem;
    }
    .c-online-zx{
        width: 47.38rem;
        height: 4.13rem;
        line-height: 4.13rem;
        text-align: center;
        border-radius: 0.94rem;
        border: solid 0.13rem #aaaaaa;
        font-size: 1.25rem;
        color: #303030;
        cursor: pointer;
    }
</style>
