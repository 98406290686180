<template>
    <div>
        <div class="customer-service">
            <div class="ctsv-wrap" :style="{display: isShow}" >
                <img class="ctsv-wrap-img" v-lazy="require('../../../assets/images/customer-service/01.png')" alt="">
                <div class="ctsv-wp-item">
                    <div class="ctsv-wp-item-t1">
                        <img class="ctsv-wp-item-t1-img" v-lazy="require('../../../assets/images/customer-service/03.png')" alt="">
                        <span class="ctsv-wp-item-t1-span">在线客服</span>
                    </div>
                    <div class="ctsv-wp-item-t2">在线客服:{{customerQrdImg[0]['name']}}</div>
                    <div class="ctsv-wp-item-t3" @click="advisoryService(0)">咨询客服</div>
                    <div class="ctsv-wp-item-t4">在线客服:{{customerQrdImg[1]['name']}}</div>
                    <div class="ctsv-wp-item-t5" @click="advisoryService(1)">咨询客服</div>
                    <div class="ctsv-wp-item-t6">
                        <img class="ctsv-wp-item-t6-img" v-lazy="require('../../../assets/images/customer-service/04.png')" alt="">
                        <span class="ctsv-wp-item-t6-span">微信公众号</span>
                    </div>
                    <div class="ctsv-wp-item-t7">
                        <img v-lazy="require('../../../assets/images/function-download/34.png')" alt="">
                    </div>
                </div>
            </div>
            <img @click='isShow=="flex"?isShow="none":isShow="flex"' class="customer-service-img" v-lazy="require('../../../assets/images/customer-service/02.png')" alt="">
        </div>
        <customerServiceQrd v-on:closeParentDialog="closeChildDialog" :dialogVisible="customerQrdIsShow" :customerServiceData="customerQrdImg[customerQrdImgIndex]"></customerServiceQrd>

    </div>

</template>

<script>
    import customerServiceQrd from '@/components/Mobile/CustomerServiceQrd';
    export default {
        name: "CustomerService",
        components:{
            customerServiceQrd
        },
        data() {
            return{
                isShow: 'none',
                customerQrdImg:[  // 客服联系二维码
                    {
                        name: '小谢',
                        img: require('../../../assets/images/xiaoxie.jpeg'),
                        phone: '13950188631',
                    },
                    {
                        name: '小沈',
                        img: require('../../../assets/images/customer-service/01.jpg'),
                        phone: '13959286021',
                    },
                ],
                customerQrdImgIndex: 0,   // 客服联系二维码下标
                customerQrdIsShow: false
            }
        },
        created: function() {
            // let change = Math.round(Math.random());
            // if(change) {
            //     [this.customerQrdImg[0],this.customerQrdImg[1]] = [this.customerQrdImg[1],this.customerQrdImg[0]];
            // }
        },
        methods:{
            // 显示客服二维码弹窗
            advisoryService(i){
                this.customerQrdImgIndex = i;
                this.customerQrdIsShow = true
            },
            /**
             * 关闭客服二维码弹窗
             */
            closeChildDialog(val) {
                this.customerQrdIsShow = val;
            },
        }
    }
</script>

<style scoped>
    .customer-service{
        position: fixed;
        bottom: .5rem;
        right: .5rem;
        z-index: 50;
        text-align: right;
    }
    .ctsv-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        width: 105%;
        position: relative;
        left: 0%;
    }
    .ctsv-wrap-img{
        width: 100%;
        height: 100%;
    }
    .ctsv-wp-item{
        position: absolute;
    }
    .ctsv-wp-item-t1,.ctsv-wp-item-t6{
        display: flex;
        align-items: center;
        font-size: 0.4rem;
        letter-spacing: 0.05rem;
        color: #000000;
        font-weight: bold;
    }
    .ctsv-wp-item-t6{
        margin-top: 1rem;
        margin-right: 3rem;
    }
    .ctsv-wp-item-t1-img,.ctsv-wp-item-t6-img{
        width: .5rem;
        height: .5rem;
        margin-left: .5rem
    }
    .ctsv-wp-item-t1-span,.ctsv-wp-item-t6-span{
        margin-left: .25rem;
    }
    .ctsv-wp-item-t2,.ctsv-wp-item-t4{
        font-size: 0.38rem;
        letter-spacing: 0.04rem;
        color: #404040;
        margin: 0.31rem 0 0 1.2rem;
    }
    .ctsv-wp-item-t3,.ctsv-wp-item-t5{
        width: 35%;
        padding: 0 .1rem;
        height: .6rem;
        background-image: linear-gradient(91deg, #46a1e8 0%, #4683ed 50%, #4667f2 100%), linear-gradient(#e5e5e5, #e5e5e5);
        border-radius: 0.75rem;
        text-align: center;
        line-height: .6rem;
        font-size: 0.38rem;
        letter-spacing: 0.04rem;
        color: #ffffff;
        margin: 0.41rem 0 0 1.5rem;
    }
    .ctsv-wp-item-t7{
        text-align: center;
        margin-top: .2rem;
    }
    .ctsv-wp-item-t7>img{
        width: 3rem;
        height: 3rem;
    }
    .customer-service-img{
        width: 2rem;
        height: 2rem;
    }
</style>
