<template>
    <div class="body">
        <Header></Header>
        <!-- 海报 -->
        <div class="poster">
            <div class="poster-t1">益帮手桶·装水管理系统 全新2.0版全新上线</div>
            <div class="poster-t2">电脑管理软件、手机APP订单配送管理端、微信公众号小程序线上订水、营销推广</div>
            <div class="poster-t2">三端秒级响应数据同步，全新升级智能下单派单</div>
            <div class="poster-wrap">
                <a href="https://ybsnew888.zhaizhishe.net/pc-download.php">
                    <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/03.png')">
                    <div>PC版</div>
                </a>
                <a href="https://ybsnew888.zhaizhishe.net/android-download.php">
                    <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/04.png')" alt="">
                    <div>安卓版</div>
                </a>
                <a href="https://itunes.apple.com/us/app/%E7%9B%8A%E5%B8%AE%E6%89%8B-%E6%96%B0%E7%89%88/id1458489949?l=zh&ls=1&mt=8">
                    <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/02.png')" alt="">
                    <div>iphone版</div>
                </a>
                <a href="https://ybsapi.zhaizhishe.net/driver.rar">
                    <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/05.png')" alt="">
                    <div>电话驱动</div>
                </a>
            </div>
        </div>
        <!-- 海报 -->
        <div class="main1">
            <div class="main1-t1">益帮手·管理系统</div>
            <div class="main1-t2">更·加·懂·你</div>
        </div>
        <div class="main2" :style="{backgroundPosition: '0 ' + main2Position + 'rem'}">
            <div class="main2-t1">益帮手，是跨越</div>
            <div class="main2-t2">用手机用电脑都可以</div>
        </div>
        <div class="main3">
            <div class="main3-l">
                <div class="main3-l-t1">多种设备完美支持</div>
                <div class="main3-l-t2">「唯有赶上进入移动时代，享受掌上管理，才是真正的便捷」</div>
                <div class="main3-l-t3 active">支持电脑、苹果手机、安卓手机三端联动</div>
                <div class="main3-l-t3">您不仅可以使用电脑端管理水店，</div>
                <div class="main3-l-t3">也可以随时随地的使用手机管理。</div>
                <div class="main3-l-t4"></div>
                <div class="main3-l-warp">
                    <div class="main3-lw-l">
                        <img v-lazy="require('../../../assets/images/function-download/09.png')" height="50" width="51"/>
                        <div>电脑版</div>
                    </div>
                    <div class="main3-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/10.png')" height="50" width="50"/>
                        <div>手机版</div>
                    </div>
                </div>
            </div>
            <div class="main3-r">
                <img class="main3-r-img" v-lazy="require('../../../assets/images/function-download/08.png')" height="368" width="668"/>
            </div>
        </div>

        <div class="main4" :style="{backgroundPosition: '0 ' + main4Position + 'rem'}">
            <div class="main4-t1">益帮手，是随时随地</div>
            <div class="main4-t2">自助下单</div>
        </div>

        <div class="main5">
            <div class="main5-l">
                <img v-lazy="require('../../../assets/images/function-download/12.png')"/>
            </div>
            <div class="main5-r">
                <div class="main5-r-t1">微信小程序商城</div>
                <div class="main5-r-t2">「微信小程序作为2018年最便捷的应用，是您最值得拥有的工具」</div>
                <div class="main5-r-t3 active">通过小程序商城自助下单，接单与财务核算都不再是难题，</div>
                <div class="main5-r-t3">更有营销工具配合，客户也能源源不断的主动上门。</div>
                <div class="main5-r-t4"></div>
                <div class="main5-r-wrap">
                    <div>
                        <img v-lazy="require('../../../assets/images/function-download/13.png')"/>
                        <div>手机支付</div>
                    </div>
                    <div>
                        <img v-lazy="require('../../../assets/images/function-download/14.png')"/>
                        <div>移动商城</div>
                    </div>
                    <div>
                        <img v-lazy="require('../../../assets/images/function-download/15.png')"/>
                        <div>营销工具</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main6" :style="{backgroundPosition: '0 ' + main6Position + 'rem'}">
            <div class="main6-t1">益帮手，是无论你在哪</div>
            <div class="main6-t2">都可心系门店</div>
        </div>

        <div class="main7">
            <div class="main7-l">
                <div class="main7-l-t1">手机管理后台</div>
                <div class="main7-l-t2">「唯有享受便捷，轻松做老板不再是难事」</div>
                <div class="main7-l-t3 active">手机也能有一款功能齐全的管理后台，</div>
                <div class="main7-l-t3">创建订单、接单、回单、创建管理商品、查看财务统统都可以。</div>
                <div class="main7-l-t3">甚至你想布置一些营销活动，它统统都可以办到。</div>
                <div class="main7-l-t4"></div>
                <div class="main7-l-warp">
                    <div class="main7-lw-l">
                        <img v-lazy="require('../../../assets/images/function-download/18.png')"/>
                        <div>手机管理</div>
                    </div>
                    <div class="main7-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/19.png')"/>
                        <div>订单管理</div>
                    </div>
                    <div class="main7-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/20.png')"/>
                        <div>配送管理</div>
                    </div>
                    <div class="main7-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/21.png')"/>
                        <div>消息通知</div>
                    </div>
                    <div class="main7-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/22.png')"/>
                        <div>营销工具</div>
                    </div>
                    <div class="main7-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/23.png')"/>
                        <div>财务管理</div>
                    </div>
                </div>
            </div>
            <div class="main7-r">
                <img class="main7-r-img" v-lazy="require('../../../assets/images/function-download/17.png')"/>
            </div>
        </div>

        <div class="main8" :style="{backgroundPosition: '0 ' + main8Position + 'rem'}">
            <div class="main8-t1">益帮手，是快捷</div>
            <div class="main8-t2">省心的操作体验</div>
        </div>

        <div class="main9">
            <div class="main9-l">
                <img v-lazy="require('../../../assets/images/function-download/25.png')"/>
            </div>
            <div class="main9-r">
                <div class="main9-r-t1">给您最好用的订单系统</div>
                <div class="main9-r-t2">「益帮手会竭尽所能给您带来最好的派单系统体验」</div>
                <div class="main9-r-t3 active">无论是电话下单还是小程序下单，</div>
                <div class="main9-r-t3">我们都可以给您带来最好的用户体验。</div>
                <div class="main9-r-t3">订单自动分配。无异常订单无需人工分配,</div>
                <div class="main9-r-t3">益帮手给您带来的是最简便高效的订单系统。</div>
                <div class="main9-r-t4"></div>
                <div class="main9-r-wrap">
                    <div>
                        <img v-lazy="require('../../../assets/images/function-download/26.png')"/>
                        <div>手机支付</div>
                    </div>
                    <div>
                        <img v-lazy="require('../../../assets/images/function-download/27.png')"/>
                        <div>移动商城</div>
                    </div>
                    <div>
                        <img v-lazy="require('../../../assets/images/function-download/28.png')"/>
                        <div>营销工具</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main10" :style="{backgroundPosition: '0 ' + main10Position + 'rem'}">
            <div class="main10-t1">益帮手，是快捷</div>
            <div class="main10-t2">省心的操作体验</div>
        </div>

        <div class="main11">
            <div class="main11-l">
                <div class="main11-l-t1">营销推广工具</div>
                <div class="main11-l-t2">「有效的组合与使用营销手段，客户还不是源源不断的来」</div>
                <div class="main11-l-t3 active">益帮手为您准备了数十个营销推广方案，</div>
                <div class="main11-l-t3">营销工具可以有效的拉新推广、留存客户、提高转化率等。</div>
                <div class="main11-l-t3">如果您想大展身手，营销工具将成为您生意场上最好的帮手。</div>
                <div class="main11-l-t4"></div>
                <div class="main11-l-warp">
                    <div class="main11-lw-l">
                        <img v-lazy="require('../../../assets/images/function-download/30.png')"/>
                        <div>获取新客</div>
                    </div>
                    <div class="main11-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/31.png')"/>
                        <div>刺激消费</div>
                    </div>
                    <div class="main11-lw-r">
                        <img v-lazy="require('../../../assets/images/function-download/32.png')"/>
                        <div>维系老客</div>
                    </div>
                </div>
            </div>
            <div class="main11-r">
                <img class="main11-r-img" v-lazy="require('../../../assets/images/function-download/33.png')" />
            </div>
        </div>
        <Bottom></Bottom>
        <CustomerService></CustomerService>
    </div>
</template>

<script>
    import Header from '@/components/Pc/Header';
    import Bottom from '@/components/Pc/Bottom';
    import CustomerService from '@/components/Pc/CustomerService';
    export default {
        name: "FunctionDownload",
        components: {
            Header,
            Bottom,
            CustomerService,
        },
        data() {
          return {
              main2Position: 2,
              main4Position: 4,
              main6Position: 6,
              main8Position: 8,
              main10Position: 10,
              scroll: '',
          }
        },
        methods: {
            menu() {
                let scroll = document.documentElement.scrollTop || document.body.scrollTop;    // 离顶部距离
                let main2Position = this.main2Position + (this.scroll-scroll)/1000;
                let main4Position = this.main4Position + (this.scroll-scroll)/1000;
                let main6Position = this.main6Position + (this.scroll-scroll)/1000;
                let main8Position = this.main8Position + (this.scroll-scroll)/1000;
                let main10Position = this.main10Position + (this.scroll-scroll)/1000;
                this.scroll = scroll;
                this.main2Position = main2Position;
                this.main4Position = main4Position;
                this.main6Position = main6Position;
                this.main8Position = main8Position;
                this.main10Position = main10Position;
            },

            // brover() {
            //     var browser = {
            //         versions: function () {
            //             var u = navigator.userAgent;
            //             var app = navigator.appVersion;
            //             return {    //移动终端浏览器版本信息
            //                 trident: u.indexOf('Trident') > -1, //IE内核
            //                 presto: u.indexOf('Presto') > -1, //opera内核
            //                 webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            //                 gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            //                 mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            //                 ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            //                 android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
            //                 iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            //                 iPad: u.indexOf('iPad') > -1, //是否iPad
            //                 webApp: u.indexOf('Safari') == -1//是否web应该程序，没有头部与底部
            //             }
            //         }(),
            //         language: (navigator.browserLanguage || navigator.language).toLowerCase()
            //     }
            //     console.log(browser.versions);
            //     if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
            //         var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            //         if (ua.match(/MicroMessenger/i) === "micromessenger") {
            //             //判断是否在微信打开
            //             this.systemVersion = "wechat";
            //             // setCookie('OS', escape(this.systemVersion))
            //             if (browser.versions.android) {
            //                 this.systemVersion = "wehatAndroid";//安卓端微信
            //                 // setCookie('OS', escape(this.systemVersion))
            //             } else if (browser.versions.ios) {
            //                 this.systemVersion = "wechatIOS";//ios端微信
            //                 // setCookie('OS', escape(this.systemVersion))
            //             }
            //             // 判断是ios还是安卓
            //         } else if (browser.versions.android) {
            //             this.systemVersion = "Android";//安卓
            //             // setCookie('OS', escape(this.systemVersion))
            //
            //         } else if (browser.versions.ios) {
            //             this.systemVersion = "ios";//ios
            //             // setCookie('OS', escape(this.systemVersion))
            //         } else {
            //             this.systemVersion = "wp";//WP
            //             // setCookie('OS', escape(this.systemVersion))
            //         }
            //     } else {
            //         this.systemVersion = "PC";//PC
            //         // setCookie('OS', escape(this.systemVersion))
            //     }
            // }
        },
        created:function (){
            // this.brover()
        },
        mounted() {
            window.addEventListener('scroll', this.menu)
        },
    }
</script>

<style scoped>
    .body{
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    /* 海报 */
    .poster{
        height: 58rem;
        background: url('../../../assets/images/function-download/01.png') no-repeat center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
    }
    .poster-t1{
        font-size: 1.88rem;
        padding-top: 7.25rem;
    }
    .poster-t2{
        font-size: 1.56rem;
        padding-top: 1.56rem;

    }
    .poster-wrap{
        display: flex;
        padding-top: 3.69rem;
    }
    .poster-wrap>a{
        width: 9.25rem;
        height: 2.69rem;
        background-color: rgba(211, 214, 216, 0.28);
        font-size: 1.13rem;
        display: flex;
        align-items: center;
        margin: 0 1rem;
        cursor: pointer;
        color: #fff;
    }
    .p-wrap-img{
        width: 1.63rem;
        height: 1.63rem;
        margin: 0 .69rem;
    }
    /* 海报 */

    .main1{
        height: 21rem;
        background: url("../../../assets/images/function-download/06.png") center;
        text-align: center;
        overflow: hidden;
    }
    .main1-t1{
        font-size: 2.96rem;
        letter-spacing: 0.29rem;
        color: #404040;
        margin-top: 3.69rem;
    }
    .main1-t2{
        font-size: 2.96rem;
        letter-spacing: 1.36rem;
        color: #29aefb;
        margin-top: 2.13rem;
    }

    .main2{
        background: url("../../../assets/images/function-download/07.jpg") no-repeat;
        background-attachment: fixed;
        text-align: center;
        height: 37.5rem;
        overflow: hidden;
        font-size: 2.19rem;
        letter-spacing: 0.44rem;
        color: #ffffff;
    }
    .main2-t1{
        margin-top: 15.5rem;
    }
    .main2-t2{
        margin-top: 1rem;
    }

    .main3{
        height: 37.5rem;
        display: flex;
        padding: 0 24.25rem;
        justify-content: space-between;
        align-items: center;
    }
    .main3-l{
    }
    .main3-l-t1{
        font-size: 2.13rem;
        letter-spacing: 0.43rem;
        color: #404040;
    }
    .main3-l-t2{
        font-size: 0.94rem;
        letter-spacing: 0.09rem;
        color: #707070;
        margin-top: 1.25rem;
    }
    .main3-l-t3{
        font-size: 0.94rem;
        letter-spacing: 0.19rem;
        color: #404040;
        margin-top: 1rem;
    }
    .main3-l-t3.active{
        margin-top: 2rem;
    }
    .main3-l-t4{
        width: 3.13rem;
        height: 0.13rem;
        background-image: linear-gradient(90deg, #0e8ef7 0%, #257aeb 50%, #3c66de 100%), linear-gradient( #29aefb, #29aefb);
        background-blend-mode: normal, normal;
        opacity: 0.99;
        margin-top: 2rem;
    }
    .main3-l-warp{
        margin-top: 1.44rem;
        display: flex;
        font-size: 0.81rem;
        letter-spacing: 0.16rem;
        color: #8e8d8d;
    }
    .main3-l-warp>div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .main3-l-warp>div>div{
        margin-top: .94rem;
    }
    .main3-lw-r{
        margin-left: 2.5rem;
    }
    .main3-r{
        margin-left: 2.25rem;
    }
    .main3-r-img{
        width: 41.75rem;
        height: 23rem;
    }

    .main4{
        background: url("../../../assets/images/function-download/11.jpg") no-repeat;
        background-attachment: fixed;
        text-align: center;
        height: 37.5rem;
        overflow: hidden;
        font-size: 2.19rem;
        letter-spacing: 0.44rem;
        color: #ffffff;
    }
    .main4-t1{
        margin-top: 15.5rem;
    }
    .main4-t2{
        margin-top: 1rem;
    }

    .main5{
        height: 37.5rem;
        display: flex;
        padding: 0 24.25rem;
        justify-content: space-between;
        align-items: center;
    }
    .main5-l{
        position: relative;
        top: -1.9rem;
    }
    .main5-l>img{
        height: 41.5rem;
        width: 39.5rem;
    }
    .main5-r{}
    .main5-r-t1{
        font-size: 2.13rem;
        letter-spacing: 0.43rem;
        color: #404040;
    }
    .main5-r-t2{
        font-size: 0.94rem;
        letter-spacing: 0.19rem;
        color: #707070;
        margin-top: 1.19rem;
    }
    .main5-r-t3{
        font-size: 1rem;
        letter-spacing: 0.2rem;
        color: #404040;
        margin-top: 1rem;
    }
    .main5-r-t3.active{
        margin-top: 2.06rem;
    }
    .main5-r-t4{
        width: 3.13rem;
        height: 0.13rem;
        background-image: linear-gradient(90deg, #0e8ef7 0%, #257aeb 50%, #3c66de 100%), linear-gradient(#29aefb, #29aefb);
        background-blend-mode: normal, normal;
        opacity: 0.99;
        margin-top: 2.13rem;
    }
    .main5-r-wrap{
        display: flex;
        font-size: 0.81rem;
        letter-spacing: 0.16rem;
        color: #8e8d8d;
        margin-top: 1.5rem;
    }
    .main5-r-wrap>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2.5rem;
    }
    .main5-r-wrap>div>div{
        margin-top: 1.38rem;
    }

    .main6{
        background: url("../../../assets/images/function-download/16.jpg") no-repeat;
        background-attachment: fixed;
        text-align: center;
        height: 37.5rem;
        overflow: hidden;
        font-size: 2.19rem;
        letter-spacing: 0.44rem;
        color: #ffffff;
    }
    .main6-t1{
        margin-top: 15.5rem;
    }
    .main6-t2{
        margin-top: 1rem;
    }

    .main7{
        height: 37.5rem;
        display: flex;
        padding: 0 24.25rem;
        justify-content: space-between;
        align-items: center;
    }
    .main7-l{
        width: 32.81rem;
        /*overflow:hidden;*/
        /*text-overflow:ellipsis;*/
        /*white-space:nowrap;*/
    }
    .main7-l-t1{
        font-size: 2.13rem;
        letter-spacing: 0.43rem;
        color: #404040;
    }
    .main7-l-t2{
        font-size: 0.94rem;
        letter-spacing: 0.09rem;
        color: #707070;
        margin-top: 1.25rem;
    }
    .main7-l-t3{
        font-size: 0.94rem;
        letter-spacing: 0.19rem;
        color: #404040;
        margin-top: 1rem;
    }
    .main7-l-t3.active{
        margin-top: 2rem;
    }
    .main7-l-t4{
        width: 3.13rem;
        height: 0.13rem;
        background-image: linear-gradient(90deg, #0e8ef7 0%, #257aeb 50%, #3c66de 100%), linear-gradient( #29aefb, #29aefb);
        background-blend-mode: normal, normal;
        opacity: 0.99;
        margin-top: 2rem;
    }
    .main7-l-warp{
        display: flex;
        flex-wrap: wrap;
        font-size: 0.81rem;
        letter-spacing: 0.16rem;
        color: #8e8d8d;
    }
    .main7-l-warp>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.44rem;
    }
    .main7-l-warp>div>div{
        margin-top: .94rem;
    }
    .main7-lw-r,.main7-lw-l{
        margin-right: 2.5rem;
    }
    .main7-r{
        margin-left: 2.25rem;
    }
    .main7-r-img{
        width: 38.19rem;
        height: 33.94rem;
    }

    .main8{
        background: url("../../../assets/images/function-download/24.jpg") no-repeat;
        background-attachment: fixed;
        text-align: center;
        height: 37.5rem;
        overflow: hidden;
        font-size: 2.19rem;
        letter-spacing: 0.44rem;
        color: #ffffff;
    }
    .main8-t1{
        margin-top: 15.5rem;
    }
    .main8-t2{
        margin-top: 1rem;
    }

    .main9{
        height: 37.5rem;
        display: flex;
        padding: 0 24.25rem;
        justify-content: space-between;
        align-items: center;
    }
    .main9-l{
        margin-right: 4rem;
    }
    .main9-l>img{
        width: 38.69rem;
        height: 28.38rem;
    }
    .main9-r{}
    .main9-r-t1{
        font-size: 2.13rem;
        letter-spacing: 0.43rem;
        color: #404040;
    }
    .main9-r-t2{
        font-size: 0.94rem;
        letter-spacing: 0.19rem;
        color: #707070;
        margin-top: 1.19rem;
    }
    .main9-r-t3{
        font-size: 1rem;
        letter-spacing: 0.2rem;
        color: #404040;
        margin-top: 1rem;
    }
    .main9-r-t3.active{
        margin-top: 2.06rem;
    }
    .main9-r-t4{
        width: 3.13rem;
        height: 0.13rem;
        background-image: linear-gradient(90deg, #0e8ef7 0%, #257aeb 50%, #3c66de 100%), linear-gradient(#29aefb, #29aefb);
        background-blend-mode: normal, normal;
        opacity: 0.99;
        margin-top: 2.13rem;
    }
    .main9-r-wrap{
        display: flex;
        font-size: 0.81rem;
        letter-spacing: 0.16rem;
        color: #8e8d8d;
        margin-top: 1.5rem;
    }
    .main9-r-wrap>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2.5rem;
    }
    .main9-r-wrap>div>div{
        margin-top: 1.38rem;
    }

    .main10{
        background: url("../../../assets/images/function-download/29.jpg") no-repeat;
        background-attachment: fixed;
        text-align: center;
        height: 37.5rem;
        overflow: hidden;
        font-size: 2.19rem;
        letter-spacing: 0.44rem;
        color: #ffffff;
    }
    .main10-t1{
        margin-top: 15.5rem;
    }
    .main10-t2{
        margin-top: 1rem;
    }

    .main11{
        height: 37.5rem;
        display: flex;
        padding: 0 24.25rem;
        justify-content: space-between;
        align-items: center;
    }
    .main11-l{
        width: 31.63rem;
        /*overflow:hidden;*/
        /*text-overflow:ellipsis;*/
        /*white-space:nowrap;*/
        margin-right: 7.5rem ;
    }
    .main11-l-t1{
        font-size: 2.13rem;
        letter-spacing: 0.43rem;
        color: #404040;
    }
    .main11-l-t2{
        font-size: 0.94rem;
        letter-spacing: 0.09rem;
        color: #707070;
        margin-top: 1.25rem;
    }
    .main11-l-t3{
        font-size: 0.94rem;
        letter-spacing: 0.19rem;
        color: #404040;
        margin-top: 1rem;
    }
    .main11-l-t3.active{
        margin-top: 2rem;
    }
    .main11-l-t4{
        width: 3.13rem;
        height: 0.13rem;
        background-image: linear-gradient(90deg, #0e8ef7 0%, #257aeb 50%, #3c66de 100%), linear-gradient( #29aefb, #29aefb);
        background-blend-mode: normal, normal;
        opacity: 0.99;
        margin-top: 2rem;
    }
    .main11-l-warp{
        display: flex;
        flex-wrap: wrap;
        font-size: 0.81rem;
        letter-spacing: 0.16rem;
        color: #8e8d8d;
    }
    .main11-l-warp>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.44rem;
    }
    .main11-l-warp>div>div{
        margin-top: .94rem;
    }
    .main11-lw-r,.main11-lw-l{
        margin-right: 2.5rem;
    }
    .main11-r{
        margin-left: 2.25rem;
        position: relative;
        top: 1.4rem;
    }
    .main11-r-img{
        width: 21.63rem;
        height: 35rem;
    }
</style>
