import Vue from "vue";
import Router from "vue-router";
// 导入组件
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
let FunctionDownload;   // 功能与下载
let AgentJoining;   // 代理加盟
let SoftwarePurchase;   // 软件购买
let ServiceSupport;   // 服务与支持
let Home;   // 首页
import Login from './views/Login';
if (flag) {     // 移动端
    FunctionDownload = require('./views/Mobile/FunctionDownload').default;
    AgentJoining = require('./views/Mobile/AgentJoining').default;
    SoftwarePurchase = require('./views/Mobile/SoftwarePurchase').default;
    // ServiceSupport = require('./views/Mobile/ServiceSupport').default;
    // Home = require('./views/Mobile/Home').default;
    // function_download = './views/Mobile/FunctionDownload';
} else {    // PC端
    FunctionDownload = require('./views/Pc/FunctionDownload').default;
    AgentJoining = require('./views/Pc/AgentJoining').default;
    SoftwarePurchase = require('./views/Pc/SoftwarePurchase').default;
    ServiceSupport = require('./views/Pc/ServiceSupport').default;
    Home = require('./views/Pc/Home').default;
}

Vue.use(Router);
//路由规则
export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        /**
         * 首页
         */
        {
            path: '/home',
            name: "home",
            component: Home
        },
        /**
         * 功能与下载
         */
        {
            path: '/',
            // path: '/function-download',
            name: "function-download",
            component: FunctionDownload
        },
        /**
         * 软件购买
         */
        {
            path: '/software-purchase',
            name: "software-purchase",
            component: SoftwarePurchase
        },
        /**
         * 代理加盟
         */
        {
            path: '/agent-joining',
            name: "agent-joining",
            component: AgentJoining
        },
        /**
         * 服务与支持
         */
        {
            path:'/service-support',
            name:'service-support',
            component: ServiceSupport
        },


        {
          path: '/function-download',
          redirect: '/',
          component: Login
        },
    ]
});