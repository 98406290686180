<template>
    <div>
        <div class="main">
            <div class="m-wrap" :style="{display: PropsIsShow ? 'flex': isShow}" @mouseover='custerMouseover()' @mouseleave='custerMouseleave()'>
                <img class="m-wrap-img" v-lazy="require('../../../assets/images/customer-service/01.png')" alt="">
                <div class="m-wp-item">
                    <div class="m-wp-item-t1">
                        <img class="m-wp-item-t1-img" v-lazy="require('../../../assets/images/customer-service/03.png')" alt="">
                        <span class="m-wp-item-t1-span">在线客服</span>
                    </div>
                    <div class="m-wp-item-t2">在线客服:{{customerQrdImg[0]['name']}}</div>
                    <div class="m-wp-item-t3" @click="advisoryService(0)">咨询客服</div>
                    <div class="m-wp-item-t4">在线客服:{{customerQrdImg[1]['name']}}</div>
                    <div class="m-wp-item-t5" @click="advisoryService(1)">咨询客服</div>
                    <div class="m-wp-item-t6">
                        <img class="m-wp-item-t6-img" v-lazy="require('../../../assets/images/customer-service/04.png')" alt="">
                        <span class="m-wp-item-t6-span">微信公众号</span>
                    </div>
                    <div class="m-wp-item-t7">
                        <img v-lazy="require('../../../assets/images/function-download/34.png')" alt="">
                    </div>
                </div>
            </div>
            <img @mouseover='custerMouseover()' @mouseleave='custerMouseleave()' class="customer-service" v-lazy="require('../../../assets/images/customer-service/02.png')" alt="">
        </div>
        <!-- 弹窗 -->
        <div class="show-qrcode">
            <el-dialog
                    :visible.sync="dialogVisible"
                    width="27.81rem" >
                <div class="qrcode-t">
                    <div class="qrcode-t-w1">
                        <img v-lazy="require('../../../assets/images/function-download/36.png')" height="34" width="115"/>
                        <img @click="dialogVisible=false" v-lazy="require('../../../assets/images/function-download/37.png')" height="20" width="20"/>
                    </div>
                    <div class="qrcode-t-w2">客户经理：{{customerQrdImg[customerQrdImgIndex].name}}</div>
                    <div class="qrcode-t-w3">
                        <img v-lazy="customerQrdImg[customerQrdImgIndex].img" height="204" width="204"/>
                    </div>
                    <div class="qrcode-t-w4">
                        <img v-lazy="require('../../../assets/images/function-download/39.png')" height="29" width="30"/>
                        <span>微信扫码联系客服经理</span>
                    </div>
                </div>
                <div class="qrcode-b">
                    联系电话：{{customerQrdImg[customerQrdImgIndex].phone}}
                    <br/>
                    <br/>
                    添加客户经理微信号：{{customerQrdImg[customerQrdImgIndex].phone}} 了解疑问
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomerService",
        props: [
            'PropsIsShow'
        ],
        data() {
            return{
                isShow: 'none',
                customerQrdImg:[  // 客服联系二维码
                    {
                        name: '小谢',
                        img: require('../../../assets/images/xiaoxie.jpeg'),
                        phone: '13950188631',
                    },
                    {
                        name: '小沈',
                        img: require('../../../assets/images/customer-service/01.jpg'),
                        phone: '13959286021',
                    },
                ],
                customerQrdImgIndex: 0,   // 客服联系二维码下标
                dialogVisible: false
            }
        },
        created: function() {
            // let change = Math.round(Math.random());
            // if(change) {
            //     [this.customerQrdImg[0],this.customerQrdImg[1]] = [this.customerQrdImg[1],this.customerQrdImg[0]];
            // }
        },
        methods:{

            /**
             * 鼠标移出
             */
            custerMouseleave() {
                this.isShow="none";
                this.$emit('FatherIsShow', false)
            },

            /**
             * 鼠标移入
             */
            custerMouseover(){
                this.isShow="flex";
                this.$emit('FatherIsShow', true)
            },

            advisoryService(i){
                this.customerQrdImgIndex = i;
                this.dialogVisible = true
            }
        }
    }
</script>

<style scoped>
    .main{
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 100;
        text-align: right;
    }
    .m-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        width: 15.44rem;
        height: 24.56rem;
    }
    .m-wrap-img{
        width: 15.44rem;
        height: 24.56rem;
    }
    .m-wp-item{
        position: absolute;
    }
    .m-wp-item-t1,.m-wp-item-t6{
        display: flex;
        align-items: center;
        font-size: 0.94rem;
        letter-spacing: 0.05rem;
        color: #000000;
        font-weight: bold;
    }
    .m-wp-item-t6{
        margin-top: 1rem;
        margin-right: 3rem;
    }
    .m-wp-item-t1-img,.m-wp-item-t6-img{
        width: 1.44rem;
        height: 1.44rem;
    }
    .m-wp-item-t1-span,.m-wp-item-t6-span{
        margin-left: .75rem;
    }
    .m-wp-item-t2,.m-wp-item-t4{
        font-size: 0.88rem;
        letter-spacing: 0.04rem;
        color: #404040;
        margin: 0.81rem 0 0 2.19rem;
    }
    .m-wp-item-t3,.m-wp-item-t5{
        width: 4.81rem;
        height: 1.5rem;
        background-image: linear-gradient(91deg, #46a1e8 0%, #4683ed 50%, #4667f2 100%), linear-gradient(#e5e5e5, #e5e5e5);
        background-blend-mode: normal, normal;
        border-radius: 0.75rem;
        text-align: center;
        line-height: 1.5rem;
        font-size: 0.81rem;
        letter-spacing: 0.04rem;
        color: #ffffff;
        margin: 0.81rem 0 0 2.19rem;
        cursor: pointer;
    }
    .m-wp-item-t7{
        margin: 0.3rem 0 0 1.8rem;
    }
    .m-wp-item-t7>img{
        width: 6.88rem;
        height: 6.88rem;
    }
    .customer-service{
        width: 5.25rem;
        height: 5.25rem;
        cursor: pointer;
    }

    /* 弹窗 */

    .show-qrcode>>>.el-dialog{
        border-radius: 1.5rem;
    }
    .show-qrcode>>>.el-dialog__body{
        padding: 0;
    }
    .show-qrcode>>>.el-dialog__header{
        display: none;
    }

    .qrcode-t{
        padding: 30px 20px 0 20px;
        height: 29rem;
    }
    .qrcode-t-w1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .qrcode-t-w2{
        width: 17.94rem;
        height: 2.13rem;
        background-color: #f7f7f7;
        border-radius: 1.06rem;
        font-size: 1.3rem;
        line-height: 2.13rem;
        color: #404040;
        margin: 1.91rem auto 0;
        text-align: center;
    }
    .qrcode-t-w3{
        text-align: center;
        margin-top: 1rem;
    }
    .qrcode-t-w4{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.38rem;
        letter-spacing: 0.07rem;
        color: #404040;
    }
    .qrcode-t-w4>span{
        margin-left: 1rem;
    }
    .qrcode-b{
        height: 5.63rem;
        font-size: 1.3rem;
        background-color: #f7f7f7;
        color: #404040;
        border-radius: 0 0 1.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* 弹窗 */
</style>
